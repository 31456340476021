import * as React from "react"

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={491.791}
    width={500}
    xmlSpace="preserve"
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        spreadMethod="pad"
        gradientTransform="scale(-4425.85 4425.85) rotate(-25.25 2.458 7.512)"
        gradientUnits="userSpaceOnUse"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff4545",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#f7931e",
          }}
        />
      </linearGradient>
      <linearGradient
        id="d"
        spreadMethod="pad"
        gradientTransform="scale(-1027.163 1027.163) rotate(-36.054 7.05 18.008)"
        gradientUnits="userSpaceOnUse"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff4545",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#f7931e",
          }}
        />
      </linearGradient>
      <linearGradient
        id="f"
        spreadMethod="pad"
        gradientTransform="scale(-649.019 649.019) rotate(-23.333 16.058 38.008)"
        gradientUnits="userSpaceOnUse"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff4545",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#f7931e",
          }}
        />
      </linearGradient>
      <linearGradient
        id="h"
        spreadMethod="pad"
        gradientTransform="scale(-661.838 661.838) rotate(-24.614 14.065 29.34)"
        gradientUnits="userSpaceOnUse"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#ff4545",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#f7931e",
          }}
        />
      </linearGradient>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M11866.5 5556.6c-580.7 0-1199.3 308.4-1702.9 559.5-244.76 122-453.21 225.9-627.72 282 173 203.2 476.52 581.2 612.82 882.7 180 398.5 281.5 517.5 416.5 608.4 39.7 26.8 58 75.8 45.6 122.1-12.4 46.3-52.8 79.5-100.6 82.8-11.2.8-22.5 1.7-33.8 2.7 93.9 90.9 211.7 206.3 299.9 296.6 219.8 224.9 549.5 562.2 1162.5 562.2 212.9 0 460.1-40.7 748.6-141 7.8-2.3 592.1-184.5 925.2-569.2 31.4-126.5 71.6-260.9 122.3-402.8 31.6-88.2 64.5-171.6 98.3-250.4-41.4-484.8-363.6-1152.1-366.3-1157.8-16.7-32-420.3-784-1469.2-872.4-43.5-3.7-87.2-5.5-131.2-5.4" />
      </clipPath>
      <clipPath id="c" clipPathUnits="userSpaceOnUse">
        <path d="M9733.2 8073.8s-103.04-121-169.97-299.8c-66.93-178.7-218.73-440.9-554.78-529.9-336.05-89.1-434.04-22.8-434.04-22.8s274.7 79.9 412.32 308.7c137.63 228.8 350.15 564.2 746.47 543.8z" />
      </clipPath>
      <clipPath id="e" clipPathUnits="userSpaceOnUse">
        <path d="M9074.24 6580s-568.61-125.2-809.64-460.5c-241.03-335.3-270.39-432.6-433.4-453.5 0 0 334.53-115.1 644.33 13.9 309.79 129.1 569.37 308.3 842.09 239.6 0 0-317.39 187.3-603.7 173.2 0 0 112.33 265.6 360.32 487.3z" />
      </clipPath>
      <clipPath id="g" clipPathUnits="userSpaceOnUse">
        <path d="M7351.89 5638.6s-117.88-212.9-407.85-290.7c-208.82-56-440.1-196.5-440.1-196.5s206.39 745.7 847.95 487.2z" />
      </clipPath>
    </defs>
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13159.4 16036.8c-2.1 22.8 42.6 47.1 111.3 64.6 34.6 8.9 59.7 39.3 61.9 75 4.3 70.7 19.7 119.3 42.5 121.4 22.7 2.2 47-42.6 64.6-111.2 8.9-34.7 39.2-59.8 74.9-61.9 70.8-4.3 119.5-19.7 121.7-42.5 2.2-22.9-42.7-47.2-111.3-64.7-34.8-8.8-59.9-39.2-62.1-75-4.3-70.8-19.7-119.3-42.4-121.5-22.8-2.2-47.1 42.6-64.6 111.3-8.9 34.7-39.3 59.8-75.1 62-70.7 4.2-119.2 19.6-121.4 42.5"
      transform="matrix(.03398 0 0 -.03398 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16099.1 7476.13c-.9 55.29 43.3 100.7 98.6 101.52 55.3.77 100.7-43.38 101.5-98.66.8-55.29-43.4-100.7-98.7-101.52-55.2-.77-100.7 43.38-101.4 98.66"
      transform="matrix(.03397 0 0 -.03397 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16088.6 4026.46c-.8 58.12 45.7 105.94 103.8 106.75 58.2.86 106-45.63 106.8-103.8.8-58.11-45.6-105.93-103.8-106.74-58.2-.86-105.9 45.63-106.8 103.79"
      transform="matrix(.0323 0 0 -.0323 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16104.1 14573.8c-5 52.7 33.6 99.5 86.4 104.5 52.7 5.1 99.5-33.6 104.5-86.3 5-52.7-33.6-99.5-86.3-104.6-52.8-5-99.6 33.7-104.6 86.4"
      transform="matrix(.0336 0 0 -.0336 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16166.1 3013.08c-.6 36.78 28.9 67.06 65.7 67.58 36.9.53 67.1-28.86 67.7-65.71.5-36.84-29-67.12-65.8-67.64-36.8-.53-67.1 28.92-67.6 65.77"
      transform="matrix(.02622 0 0 -.02622 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16188.6 15053.5c-2.9 30 19.1 56.6 49.1 59.5 30 2.8 56.6-19.2 59.5-49.2 2.8-30-19.2-56.6-49.2-59.4-30-2.9-56.6 19.1-59.4 49.1"
      transform="matrix(.03037 0 0 -.03037 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16107.1 11779c0 53.3 43.2 96.5 96.5 96.5 53.2 0 96.4-43.2 96.4-96.5 0-53.2-43.2-96.4-96.4-96.4-53.3 0-96.5 43.2-96.5 96.4"
      transform="matrix(.0334 0 0 -.0334 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M728.155 952.24c.305-3.2-5.991-6.62-15.646-9.09a11.603 11.603 0 0 1-8.706-10.53c-.603-9.95-2.77-16.78-5.971-17.09-3.201-.3-6.618 6-9.089 15.65a11.625 11.625 0 0 1-10.54 8.71c-9.956.6-16.8 2.76-17.105 5.96-.307 3.22 6 6.65 15.657 9.11a11.61 11.61 0 0 1 8.719 10.54c.602 9.96 2.769 16.79 5.97 17.09 3.2.31 6.617-5.99 9.089-15.66a11.612 11.612 0 0 1 10.552-8.7c9.94-.6 16.764-2.77 17.07-5.99"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M4078.3 16010.8c1.65-29.1-56.55-57.9-144.83-76.8-44.56-9.7-77.98-47-82.49-92.4-8.94-89.9-30.95-150.9-60.04-152.6-29.08-1.6-57.82 56.6-76.81 144.8-9.59 44.6-47.02 78.1-92.39 82.6-89.91 8.9-151.12 30.9-152.77 60-1.65 29.3 56.65 58 144.93 76.9 44.63 9.6 78.11 47 82.63 92.4 8.92 90 30.93 151 60.01 152.7 29.09 1.6 57.82-56.6 76.83-145 9.59-44.6 47.07-78 92.49-82.5 89.78-8.8 150.79-30.9 152.44-60.1"
      transform="matrix(.02157 0 0 -.02157 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M3564.6 16181.3c4.33-67.4-46.86-125.6-114.33-129.9-67.46-4.4-125.67 46.8-129.99 114.3-4.33 67.5 46.86 125.7 114.34 130 67.46 4.3 125.66-46.9 129.98-114.4"
      transform="matrix(.02778 0 0 -.02778 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M3480.17 16202c3.57-55.8-38.72-103.9-94.47-107.4-55.74-3.6-103.83 38.7-107.4 94.4-3.58 55.8 38.72 103.9 94.47 107.4 55.74 3.6 103.83-38.7 107.4-94.4"
      transform="matrix(.03362 0 0 -.03362 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M473.151 1169.28c1.097-11.51-7.347-21.73-18.859-22.83-11.513-1.1-21.734 7.35-22.831 18.86-1.097 11.51 7.347 21.73 18.859 22.83 11.513 1.1 21.734-7.35 22.831-18.86"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M5169.54 16244.3c2.02-31.7-21.99-59-53.69-61-31.65-2.1-59 22-61.02 53.6-2.04 31.7 21.99 59 53.69 61.1 31.65 2 59-22 61.02-53.7"
      transform="matrix(.03042 0 0 -.03042 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M620.973 1436.18c.564-5.92-3.779-11.18-9.699-11.75-5.921-.56-11.178 3.78-11.742 9.7-.564 5.92 3.778 11.18 9.699 11.74 5.921.57 11.178-3.77 11.742-9.69"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M3298.84 16123.4c0-97.5-79.07-176.6-176.6-176.6s-176.6 79.1-176.6 176.6 79.07 176.6 176.6 176.6 176.6-79.1 176.6-176.6"
      transform="matrix(.01824 0 0 -.01824 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16046.6 9264.88c-26.4-240.62-67.4-481.24-122.9-720.28-127.4-549.23-332.4-1090.14-620.2-1607.65-1659-2985.28-5423.58-4060.38-8408.86-2401.71-2377.02 1320.87-3542.89 3976.95-3081.87 6511.56 118.06 648.7 342.32 1289.1 680.16 1897.3 527.76 949.3 1268.22 1705.5 2129.03 2241.6 138.25 86.7 280.06 167.5 424.44 242.3 777.33 403.2 1633.33 638.1 2508.53 687.2 7.72.3 15.39.9 23.11 1.3 53.59 2.9 107.13 5.1 161.06 6.4 106.83 2.9 213.65 2.9 320.5.3 726.1-18.3 1456.9-165.2 2157.9-450.4 231.6-93.7 460.4-202.8 684.3-327.3 48.1-26.9 96-53.9 143.1-82.1 28.6-16.7 56.8-34 84.7-51.3 2093.7-1280.5 3170.4-3630.2 2917-5947.22"
      transform="matrix(.03109 0 0 -.03109 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16086.5 9287.91c-26.6-241.37-67.5-482.43-123.3-722.21-127.7-550.64-333.2-1092.6-621.6-1611.55-1663.1-2992.58-5437.13-4070.45-8429.76-2407.71-2383.03 1324.09-3551.82 3986.92-3089.56 6527.86 118.46 650.1 343.19 1292 681.89 1901.8 367.19 660.5 836.8 1227.7 1378.81 1692.9 238.79 204.9 491.37 389.9 755.5 554.5 138.59 86.7 280.75 167.7 425.44 242.6 779.21 404.3 1637.49 640 2514.66 688.9 7.64.4 15.33 1 23.37 1.3 53.42 2.9 107.24 5.1 161.31 6.4 106.94 3.2 214.18 3.2 321.44.3 727.7-18.2 1460.4-165.5 2163.1-451.3 232.1-93.8 461.7-203.3 686.1-328.2 48.3-26.9 96-54.1 143.4-82.2 28.5-16.7 57-34.3 84.8-51.6 332.6-203.6 640-433.8 920.1-687 1487.6-1342.6 2217.8-3320.4 2004.3-5274.79"
      transform="matrix(.03101 0 0 -.03101 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8743.53 15461.7c-149.49 302.3-207.68 611.4-229.86 818.4 209.21 19.9 404.09-66.1 483-231.8 96.89-203.5-16.17-455.5-253.14-586.6"
      transform="matrix(.02643 0 0 -.02643 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M6982.66 4593.22c3023.44-1679.87 6835.84-591.06 8516.04 2432.34 291.3 524.06 499.1 1071.72 628 1627.91 56.4 242.03 97.8 485.77 124.4 729.51 47.5 434.16 48.9 869.72 6.2 1299.42-2-19.8-4.1-39.6-6.2-59.5-26.6-243.7-68-487.5-124.4-729.49-128.9-556.23-336.7-1103.89-628-1627.95-1680.2-3023.4-5492.6-4112.21-8516.04-2432.29C5117.53 6869.66 3988.7 8717.76 3792.12 10691.6c-240.66-2411.11 938.07-4846.5 3190.54-6098.38"
      transform="matrix(.0307 0 0 -.0307 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14352.2 9732.01c-177.4-791.67 65.5-1432.47 542.5-1431.27 477 1.19 1050.5 623.64 1227.9 1415.25 177.4 791.71-108.5 1452.81-585.6 1451.61-477-1.2-1007.4-644-1184.8-1435.59"
      transform="matrix(.02959 0 0 -.02959 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15616.7 11474.9c-347.8-650.7-308.2-1490.08 91.8-2105.69 135-200.27 326.1-366.45 567.7-428.3 14.7-3.49 23.8-18.53 20-33.23-3.2-12.77-15-21.08-27.7-20.44-133.2 6.5-264.8 52.74-380.7 122.55-765.8 498.29-903 1816.31-326.8 2503.11 25.7 30.5 75.3-2.4 55.7-38"
      transform="matrix(.02648 0 0 -.02648 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8134.55 16284c-389.99-16-697.36-298.8-686.55-631.6 10.76-332.8 335.65-589.6 725.58-573.6 389.94 16 697.31 298.7 686.55 631.6-10.81 332.8-335.65 589.6-725.58 573.6"
      transform="matrix(.02703 0 0 -.02703 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8287.43 16180.2c-273.7-119.8-411.57-393.8-307.88-612.1s409.66-298.1 683.42-178.3c273.7 119.8 411.57 393.8 307.88 612.1s-409.66 298.1-683.42 178.3"
      transform="matrix(.0265 0 0 -.0265 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8743.53 15461.7c-149.49 302.3-207.68 611.4-229.86 818.4 209.21 19.9 404.09-66.1 483-231.8 96.89-203.5-16.17-455.5-253.14-586.6"
      transform="matrix(.02643 0 0 -.02643 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff8a3b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8055.92 14807.1c-630.31-66.7-1091.97 592.7-613.83 1091 390.46 401.9 1216.28 285.1 1345.79-303.2 23.22-115.5 9.09-236.2-40.42-342.2-15.59-35.2-37.96-66.4-57.75-98.5-5.67-9.2-17.78-12.1-26.97-6.3-9.48 5.7-12 18.2-6.17 27.4 68.57 124.4 90.05 270.4 51.3 401.1-117.35 412.5-664.51 538.6-1012.63 346.2-406.89-212.4-444.34-712.9-41.21-954.5 117.74-71.5 257.51-108 399.87-112.2 30.16-.8 31.9-45.6 2.02-48.8"
      transform="matrix(.02743 0 0 -.02743 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff8a3b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9157.23 16275.9c199.49-240.7 226.34-603.1 76.94-878.4-143.65-281.1-429.16-469.5-730.95-539.9-598.68-150.4-1352.4 212-1348.78 888.7 1.32 25 37.67 26.1 40.26 1.2 15.47-143 68.26-278 150.31-391.4 403-545.9 1327.11-523.3 1728.38 12.2 103.22 137.2 162.16 307.4 160.21 479.4-1.04 138.7-43.76 276.9-117.37 399.6-16.16 25.7 22.2 52.7 41 28.6"
      transform="matrix(.02675 0 0 -.02675 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M7234.36 16293.8c770.64-94.1 1316.93-767.9 1391.95-1519 95.88-861.2-362.39-1749.1-1106.96-2185.4-157.24-92.6-328.4-164.7-504.58-211.8-175.79-46.2-358.96-64.6-539.88-55.6-39.05 2.4-39.68 59.4.08 61.6 30.86 3.5 100.41 9.5 129.7 12.6 25.34 5.6 101.02 16.7 127.88 21.8l83.93 20.4c248.11 62.5 486.24 177.1 691.79 329.2 889.67 632.4 1263.8 2013.1 555.06 2910.2-211.68 267.2-509.53 455.7-843.09 540.8-45.53 10.5-33.65 81.4 14.12 75.2"
      transform="matrix(.02331 0 0 -.02331 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M5709.39 14978.5c-158.68-791.8 243.69-1545.7 898.72-1683.9 655.02-138.3 1314.67 391.5 1473.32 1183.3 158.72 791.7-243.68 1545.6-898.71 1683.9-655 138.2-1314.64-391.6-1473.33-1183.3"
      transform="matrix(.02267 0 0 -.02267 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M6958.71 13689.5c437.8-5.1 883.05 292.8 1111.76 793.6 304.25 666.9 109.3 1441.3-435.45 1729.8-76.84 40.9-156.71 69.7-237.96 87.1-321.4-131-609.54-406-784.19-788.2-308.98-677.3-150.84-1455.5 345.84-1822.3"
      transform="matrix(.02232 0 0 -.02232 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M5671.37 15095.3c357.03 836.4 1484.54 1204.7 2115.26 439.1 662.75-849.4 2.55-2218.1-1043.37-2309.5-285.16-29.4-583.29 55.1-802.53 240.1-109.61 90.4-197.39 203.9-261.14 327.3-7.96 15.4-1.93 34.4 13.48 42.3 14.36 7.4 31.78 2.7 40.53-10.4 72.43-109.7 163.42-205.4 268.77-278.9 825.89-557.2 1846.71 273.8 1841 1170.1 7.52 865.6-818.05 1323.9-1560.82 891.9-222.82-126.8-408.02-320.2-541.68-547.1-24.24-42.2-88.89-9.3-69.5 35.1"
      transform="matrix(.02291 0 0 -.02291 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16200.2 14478.3c99.8-626.7-78.1-1298.5-481.2-1792.3-842-1051.5-2413.5-1149.7-3477.4-380.6-515.6 367.3-902.8 917.5-1049.2 1532.6-3.9 16.3 6.1 32.7 22.4 36.7 15.6 3.7 31.2-5.3 36-20.2 194.1-576.3 581.8-1076.5 1085.8-1410.8 1158.1-772.1 2794.5-533.4 3499 722.3 221.6 394.5 316.1 850.3 289.7 1304.2-3.5 45.4 66.7 56 74.9 8.1"
      transform="matrix(.02367 0 0 -.02367 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13680.8 16182.5c-988.7-117.4-1725.8-949.7-1646.3-1859 79.5-909.2 945.6-1551.1 1934.4-1433.7 988.7 117.5 1725.7 949.9 1646.2 1859.1-79.5 909.3-945.6 1551.1-1934.3 1433.6"
      transform="matrix(.02339 0 0 -.02339 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12953.5 14778.5c160.8-591.1 692.5-1064 1387.7-1159.9 925.9-127.2 1791.6 462.5 1934 1317.4 20.2 120.7 24.8 240.2 14.9 356.9-281.7 377.1-725.6 648.3-1256.2 721.7-940.3 129.1-1824.7-412.8-2080.4-1236.1"
      transform="matrix(.02228 0 0 -.02228 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff8a3b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14050.8 16293.3c783.9-103.8 1451.4-787.5 1520.5-1581.2 74.5-727-381.5-1430.7-1076.7-1657.3-1124.6-379.6-2365.9 492.7-2364.5 1683.5 2.8 369.5 133.9 739 378.8 1009.7 11.5 12.7 30.9 13.7 43.6 2.3 11.9-10.7 13.4-28.8 4-41.5-104.9-139.2-184.7-295.5-235.3-460.1-368.6-1241.8 894.8-2406.5 2107-1999.4 475.4 158 849.2 579 934.1 1073.8 155.1 762-359.7 1554.1-1076.5 1818.3-69.9 30.3-174.6 56.6-248.7 76.7-46.7 10.4-34.1 81.9 13.7 75.2"
      transform="matrix(.02333 0 0 -.02333 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M10450.1 11307.2c-440.9-232-785.05-570.6-977.42-1002.8-348.6-783.44-101.58-1649.01 536.02-2411.05 418.3-500.03 1012.2-935.44 1733.7-1227.81 1821.2-737.98 3765.5-284.5 4342.8 1012.73 214.8 482.71 210.1 1014.3 25.7 1529.48-311.4 869.35-1384.1 1131.25-2527.6 1594.65l-3133.2 504.8"
      transform="matrix(.02052 0 0 -.02052 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9616.3 10399c-314.21-754.96-27.28-1589.36 460.2-2202.01 983-1236.14 2678.5-1885.63 4237.6-1662.82 677.2 105.57 1360.7 410.84 1761.3 986.43 57.5 80.94 108.8 166.65 153.2 256.08 8.7 17.58 29.9 24.71 47.5 15.99 16.6-8.26 23.9-27.96 17.1-44.94-38.6-96.1-84.9-189.39-138.3-278.82-384.1-634.02-1095.7-999.08-1807.3-1128.82-1814.9-323.46-3926.5 588.93-4759.34 2269.78-276.01 562.79-341.26 1254.33-52.21 1824.83 26.75 49.5 99.65 16.9 80.25-35.7"
      transform="matrix(.0203 0 0 -.0203 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13685.1 11446.2c-1525.1 319.3-2908.3-331.4-3089.2-1453.38-181-1121.98 908.7-2290.35 2433.9-2609.65 1525.1-319.3 2908.3 331.45 3089.3 1453.42 180.9 1121.98-908.8 2290.31-2434 2609.61"
      transform="matrix(.01963 0 0 -.01963 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13916.8 10604.3c-1340.6 177.9-2471.6-371.5-2526.1-1227.22-54.4-855.61 988.1-1693.4 2328.8-1871.31 1340.6-177.83 2471.5 371.55 2526 1227.23 54.5 855.61-988.1 1693.5-2328.7 1871.3"
      transform="matrix(.01917 0 0 -.01917 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15219.5 7615.73c714.2 395.75 1026.1 1166.43 730.2 1936.42-554.1 1403.55-2399.8 2173.45-3828.1 1840.95-742.4-168.6-1387.6-715-1386.4-1520.02 6.2-776.27 546.1-1452.98 1156.3-1886.41 212.5-150.57 442-277.68 681.1-385.38 18.4-8.33 26.7-30.07 18.3-48.52-8.1-17.82-28.6-26.06-46.6-19.16-124.4 47.97-248.4 98.37-368.1 158.27-360.8 179.86-695.3 416.55-974.2 709.38-576 588.32-903.7 1518.45-470.8 2283.84 418.5 754.6 1333 1045.8 2150 1030.9 1087.7-27 2180.9-516.4 2859.4-1376.4 336.9-432.06 559.4-988.58 482-1544.72-57.3-415.06-286.6-792.74-615.1-1046-107.6-84-224.8-153.72-345.7-213.7-22.5-11.14-49.8-1.96-61 20.57-10.9 21.91-2.4 48.36 18.7 59.98"
      transform="matrix(.0196 0 0 -.0196 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12508.6 13610.8c221.6 145.7 386.4 342.9 466 582 144.1 433-31.6 878.9-408.8 1249.7-247.5 243.2-584.7 443.2-982.1 560.8-1003.1 296.7-2009.87-44.2-2248.62-761.4-88.84-266.9-58.79-548.2 65.61-811.7 209.9-444.5 695.41-812.5 1325.31-998.8l1782.6 179.4"
      transform="matrix(.03046 0 0 -.03046 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff8a3b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12705.3 13937.1c117.6 400.9-71.7 811.6-352 1098-566.2 579.9-1463.1 827-2249.2 638.9-390.83-97-769.75-334.7-921.17-723.7-4.61-11.9-18.05-17.9-29.99-13.2-11.15 4.3-17 16.5-13.93 27.8 57.3 209.7 189.04 395.7 355.43 534.7 987.66 800.4 2883.56 283.3 3285.26-935.9 30.9-105.4 47.7-215.7 44.4-325.5-2.7-109.8-25.2-218.3-64.2-319.3-5.8-14.8-22.4-22.1-37.3-16.4-13.8 5.4-21.1 20.6-17.3 34.6"
      transform="matrix(.03103 0 0 -.03103 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcac38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11411 14058.5c811.8-88.9 1500.1 321 1537.3 915.5 37.3 594.4-590.7 1148.3-1402.5 1237.2-811.8 88.8-1500.1-321.1-1537.3-915.5-37.22-594.5 590.7-1148.4 1402.5-1237.2"
      transform="matrix(.0296 0 0 -.0296 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11444.5 14682.7c694-24 1244.5 313.2 1229.7 753.2-14.9 440-589.6 816.2-1283.5 840.1-694.1 24-1244.6-313.2-1229.7-753.2 14.9-440 589.5-816.1 1283.5-840.1"
      transform="matrix(.0295 0 0 -.0295 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff8a3b",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M10514.5 16106.3c-347.1-242.3-469.4-661-274.8-1042.2 406.1-794.7 1712.1-1119 2427.2-575.9 402.7 317.6 374.8 824.9 67.3 1201.4-213.8 266.8-514.8 449.7-836.3 560.2-13 4.2-20.1 18.4-15.5 31.4 4.2 12.1 17.2 18.8 29.4 15.5 68-18.2 136.1-37.9 202.4-62.7 334.5-126.2 644.4-339.8 835.3-648.5 258.1-404.8 194.9-912-184.9-1217.5-465.4-371.4-1129.9-378.6-1670.6-187.3-533.4 189.4-1075.7 650.3-1060.8 1261.1 7.3 149.2 54.8 295.3 135 418.4 79.1 122.7 189.1 222.4 313 296.9 33.5 19.8 64.4-28.6 33.3-50.8"
      transform="matrix(.02937 0 0 -.02937 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffd243",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14730.5 10014.2c-159.1-686.87 27.6-1235.83 417.1-1226.18 389.4 9.65 834.2 574.29 993.3 1261.08 159.1 686.9-27.6 1235.9-417.1 1226.2-389.4-9.6-834.2-574.3-993.3-1261.1"
      transform="matrix(.02885 0 0 -.02885 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16231.5 10867.4c-211.1 362.7-595 404.9-930.5 182.9-505.1-339.8-791.2-1002-812.4-1599.29-21.4-461.31 198.4-1053.28 759.1-934.55 484.8 125.19 812.3 600.79 997.1 1043.5 11.4 28.74 55.2 13.24 46.3-16.33-76.5-249.13-195.7-487.9-360.5-695.94-82.8-103.49-177.1-199.11-285-280.38-358.3-279.06-842.9-302.02-1120 102.5-301 463.09-226.4 1076.97-35.2 1565.79 194.3 473.7 586.4 994.9 1130.3 1059.3 209 21.4 424.5-65.7 556.4-228.8 43.6-52.4 79-110.1 108.2-170 7.3-15.2 1-33.4-14.1-40.8-14.5-7-31.7-1.6-39.7 12.1"
      transform="matrix(.02928 0 0 -.02928 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffedb4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15670.5 8660.44c-26.8 8-53.9 15.85-81.2 23.54 143 192.21 248.1 408.98 317.3 634.43 5.5 18.15-9.3 30.87-23.9 30.87-8.6 0-17.1-4.46-21.3-14.92-53.2-127.57-118.6-258.02-196.6-381.59 106.6 189.33 191.8 404.41 243.1 633.86 88 392.51 60.5 752.47-48.3 1013.37 4.4-2.5 9.5-3.8 14.6-3.8 4.4 0 8.8.9 13 2.9 14.7 7.2 20.9 25 13.8 39.9-28.5 58.5-63.1 114.8-105.6 166-96.7 119.4-239.2 197.1-390.6 219.6-67.4 374.9-152.8 691.6-194.4 963.2 14.4 4.1 28.5 8.4 42.5 12.8 23.4-10.7 47.1-20.6 71.1-29.7 149.4-56.9 307.9-83.2 448.6-86.8 11.7-.3 23.3-.5 34.8-.5 15.1 0 29.9.3 44.4.8 59.4 2.1 114.3 8.5 162.1 18.4 166.5-418.3 286.1-964 263.7-1556.2-34.7-916.99-357-1494.63-607.1-1686.16"
      transform="matrix(.02999 0 0 -.02999 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fedeaf",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15781.2 8876.92c-37 9.47-74.5 18.51-112.6 27.18-7.7 58.6-3.5 134.5 15.8 228.11 36.7 178.77 63 350.06 80.9 514.55 21.6 66.39 40.7 134.49 56.7 203.86 96.3 415.48 63.4 779.48-65.6 995.08-11.5 96.2-25 188.9-39.7 278.7 169.9-23.9 324.5-125.2 433.1-311.7 2.7-4.9 6.7-8.6 11.3-11.2 110.9-265.8 138.9-632.7 49.3-1032.63-52.4-233.81-139.2-452.98-247.8-645.91-54.4-86.35-114.8-169.41-181.4-246.04"
      transform="matrix(.02943 0 0 -.02943 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffedb4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16143.3 9878.07c49.8 459.83 34.2 866.63-9.1 1227.73 132.1-220.8 165.8-593.6 67.2-1019-16.4-71-36-140.76-58.1-208.73"
      transform="matrix(.02874 0 0 -.02874 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#c19999",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15969.4 8895.7c-34.5 9.62-69.5 19.03-105 28.01 66.9 77.04 127.6 160.53 182.3 247.34 79.9 126.58 146.9 260.2 201.4 390.89 4.3 10.71 13 15.28 21.8 15.28 15 0 30.1-13.02 24.5-31.63-70.9-230.94-178.5-452.99-325-649.89m291.8 1958.8c-5.2 0-10.4 1.4-15 4-4.5 2.5-8.5 6.3-11.3 11.1-109.2 187.5-264.5 289.4-435.4 313.4-6.1 37.4-12.5 74.2-19.1 110.4 155-23.1 301.1-102.6 400.1-225 43.5-52.4 79-110.1 108.1-170.1 7.4-15.1 1-33.4-14.1-40.7-4.2-2.1-8.8-3.1-13.3-3.1"
      transform="matrix(.02927 0 0 -.02927 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffedb4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 14115.1c-22.2 22-45.7 42.7-70.4 62-15.1 96-27.3 194.9-38.2 286.7-4 33.4-7.9 65.9-11.6 97 65.8-177.5 103.8-325.5 120.2-445.7m-959.9 452.5c-74.4 81.1-146 156.1-209.3 226.9 6.6 58.3 9.4 119.4 8.4 183.3-2.6 167.6-52.3 336.4-137.5 482.1 52.4 35.1 120.6 53.9 198.6 53.9 200.9 0 466.5-124.8 693-416.7l-6.7-71.4c-1.6-.1-44.6-2-105.9-17-85.2-20.8-205.8-67-300.3-168.8-36.4-39.2-69-86.8-94.1-144.3-5.4-12.6-10.9-25.7-16.1-39.3-10.7-27.5-20.8-57.1-30.1-88.7"
      transform="matrix(.02827 0 0 -.02827 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9933.75 16095.7c-55.09 0-109.68-.7-163.28-2.2-50.72-1.2-102.83-3.3-159.5-6.3l-11.12-.6c-4.32-.3-8.64-.6-12.95-.8-858.98-48.1-1718.14-283.5-2483.57-680.6-143.22-74.1-284.62-154.8-420.2-239.8-875.05-545-1604.19-1312.7-2108.75-2220.2-327.31-589.4-553.96-1221.6-673.65-1879.2-469.65-2581.95 757.01-5173.65 3052.38-6449.16 939.62-522.04 1958.74-769.78 2964.36-769.83 2153.73-.05 4245.73 1136.34 5364.03 3148.58 278.8 501.45 485.5 1037.19 614.2 1592.23 54.8 235.89 95.8 475.89 121.7 713.31 256.8 2347.87-877.2 4659.87-2889 5890.27-27.8 17.3-55.5 34.3-83.6 50.6-48 28.8-96.6 56.1-142.3 81.7-218.9 121.7-446.9 230.7-677.5 324-682.1 277.5-1401.1 427.6-2137.2 446.2-51.6 1.2-103.03 1.8-154.05 1.8m-14.99-12408.2c-1053.36 0-2095.07 267.65-3041.24 793.36C4523.9 5788.72 3266.11 8446.28 3747.68 11093.8c122.74 674.4 355.13 1322.7 690.73 1926.9 517.34 930.6 1265.05 1717.8 2162.29 2276.6 139.06 87.2 284.12 170 431.07 246 784.68 407.1 1665.63 648.5 2547.48 697.9l10.92.6c4.52.3 8.93.6 13.45.8 57.22 3.1 110.82 5.2 162.83 6.5 105.76 2.8 215.24 2.9 325.25.3 755-19 1492.3-173 2191.8-457.5 236.3-95.6 470.1-207.4 694.8-332.3 46.9-26.3 96.6-54.3 145.7-83.7 28.5-16.6 57.4-34.4 86.1-52.1 1030-630 1851.4-1537.7 2374.7-2625 512.2-1064 715.2-2244.7 587.3-3414.54-26.7-243.58-68.7-489.73-124.9-731.57-132-569.19-343.8-1118.53-629.7-1632.68-814.9-1466.33-2151.9-2527.53-3764.9-2988.19-572.1-163.42-1154.8-244.32-1733.84-244.32"
      transform="matrix(.031 0 0 -.031 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14285.4 9851.99c-30.7-407.62-13.8-951.25 105.8-1415.06-22.6-92.45-28.7-323.89 48.5-506.3-39.7-152.55-29.5-300.12 42.5-404.45 147-213.02 536.9-176.69 653.8-162.32 198.2 24.26 249.9 103.9 292.2 91.71 42.2-12.2 250.7-123.25 427.5-1.37 148.5 102.28 162.3 350.82 132.9 605.84 197.2-30.3 282.2 68.87 282.2 187.82 0 226.96-401.3 418.58-410.2 470.9 439.4 493.8 438.7 1271.6 132.9 1770.54-226.7 369.8-718.6 737.2-1490.3 370.9"
      transform="matrix(.02472 0 0 -.02472 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#5e3519",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14183 9746.25c2.9-347.43 22-695.66 78.2-1038.22 17.8-113.48 41.3-225.59 69.9-335.68 3.7-14.22 3.8-28.5.9-41.92-7.1-31.34-10.6-70.87-11.4-110.33-2.2-111.87 14.9-234.27 57.5-334.44 8.4-19.89 9.6-41.07 4.7-60.58-42.7-162.48-20.6-350.39 152-408.7 78.7-30.29 174.5-40.26 270.3-39.46 113.4 3.26 234.2 9.3 335 62.8 50.2 28.2 110 58 171.7 31.34 13.2-5.85 40.6-14.96 53.8-20.38 381.3-134.65 386.7 242.58 353.6 513.78-6.6 57.14 34.4 108.73 91.5 115.32 14.7.43 30.7-.86 46.6-2.15 26.4-2.22 52.6-4.37 72.6 1.35 49.1 12.5 64.1 30.42 67.8 80.47-6.6 92.97-90.4 158.91-168.7 226.51-58.7 48.33-123.9 89.28-183.4 140.75-46.7 36.63-76.7 116.54-28.3 168.63 651.3 705.82 281 2245.56-829.7 2105.66-141.4-14.3-279.2-52.9-416.5-95.5-50.8-15.7-83 55.2-38.3 83.5 415.1 271.2 952.4 271.5 1332.2-68.8 597.3-538.2 635-1559.9 108.2-2160.68l24.7 86.01c-5.2 23.83-9.2 27.15-15.6 36.51 53.9-46.05 119-86.87 176.6-134.58 110.8-89.96 250.2-221.53 246.8-392.38-1.8-218.14-205.8-319.54-398.9-281.61l119.2 114.76c17.2-166.85 27.2-347.92-36.6-514.16-89.5-242.88-368.7-306.61-592.3-215.98-12.2 5.24-44.4 16.32-55.8 21.31-1.7.67-3.8 1.29-.1.24 6.8-1.97 14.7-2.4 20-2.28 11.5.62 14.7 2.71 14.1 2.28-40.5-19.45-83.3-43.71-127.4-57.75-308.1-89.64-839.8-87.61-953.7 287.71-38.3 125.91-26.6 250.21 2.7 362.82l4.5-61.51c-69.8 183.72-86.1 368.18-49.3 560.28l.3-44.89c-59.2 235.32-88.9 474.63-98.3 713.45-8.8 238.46.9 477.59 37.1 713.4 9.6 54.73 90.7 49.5 91.8-6.9"
      transform="matrix(.02498 0 0 -.02498 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#b17e4f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13491.5 10089.4c-915.1-509.22-2853.5-995.35-4233.55 140.9-1367.95 1126.3-752.1 3134.6-1351.27 3863.5-336.21 408.9-862.04 319.1-1184.77-10.6-233.52-238.6-524.71-500.1-878.78-359.3-828.74 329.5-312.27 1692.6 743.87 2090.9 1286.39 485.2 2757.5-339.6 2757.5-2343.8 0-971.1 96.15-1774.4 592.45-2278.3 453.95-460.8 1407.85-783.7 2831.65-177.1l722.9-926.2"
      transform="matrix(.02102 0 0 -.02102 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M5433.31 14313.3c10.02-260.8 137.02-481.1 409.73-589.4 354.13-140.7 645.29 120.6 879 359.4 322.52 329.6 848.47 419.5 1184.67 10.5 599.18-728.8-16.61-2737.1 1351.21-3863.3 1114.28-917.71 2592.98-777.23 3602.68-421.04 240.8 84.87 454.8 182.11 631.1 280.04l-723.1 926.2c-1423.9-606.8-2377.7-283.7-2831.69 177.1-496.15 503.8-592.36 1307.2-592.36 2278.4 0 2004.1-1471.05 2828.8-2757.41 2343.8-708.79-267.5-1174.65-969.1-1153.83-1501.7"
      transform="matrix(.02102 0 0 -.02102 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffe285",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9930.72 13940.1c0 251.5-21.91 485.7-62.78 701.9-32.98 338.2-108.92 639-218.84 901.8 10.37 15.1 20.75 30.3 30.88 45.6 178.21-379.2 282.55-844.6 282.55-1393.4l-31.81-255.9m-397.58 1844.7c-105.82 192.7-232.08 360.4-373.82 502.6 1.32 4.2 2.71 8.3 4.03 12.6 146.84-133.7 278.83-292.9 391.31-477.2-7.05-12.8-14.25-25.4-21.52-38"
      transform="matrix(.01987 0 0 -.01987 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#9b5959",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9940.13 16012.6c-36.37 86.8-76.24 169.6-119.46 248.2 7.49 13 14.91 26 22.17 39.2 46.41-76.1 89.47-156.1 129.11-240.4-10.45-15.7-21.14-31.5-31.82-47"
      transform="matrix(.01929 0 0 -.01929 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdc97d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9149.59 16270.1c-6.58 6.5-13.15 13-19.72 19.5 1.47 3.4 2.94 6.9 4.41 10.4 6.49-5.7 12.91-11.5 19.33-17.3-1.32-4.3-2.71-8.5-4.02-12.6"
      transform="matrix(.0199 0 0 -.0199 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#9b5959",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9051.59 16149.9c-55.13 53.9-112.55 103.9-172.04 150.1 60.79-42.8 119.67-89.4 176.41-139.8-1.46-3.4-2.92-6.9-4.37-10.3"
      transform="matrix(.02006 0 0 -.02006 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9563.41 14190.2C9331.45 15416.5 8469.29 16052 7545.32 16052c-248.62 0-501.83-46.1-748.32-139-604.91-228.3-1037.44-765.1-1153.08-1253.6 1.63 541.2 473.88 1233.9 1183.87 1501.6 246.58 93 499.62 139 748.37 139 397.46 0 783.68-117.7 1113.02-349.5 58.22-45.2 114.41-94.1 168.35-146.8 6.38-6.3 12.76-12.6 19.13-19 137.37-137.8 259.73-300.3 362.28-487.1 40.66-73.9 78.17-151.8 112.39-233.5 106.53-254.6 180.12-546.2 212.08-873.9"
      transform="matrix(.0205 0 0 -.0205 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M5763.9 15184.2c10.62-276.7 145.35-510.4 434.66-625.3 375.67-149.3 684.55 128 932.48 381.3 342.14 349.7 900.08 445 1256.75 11.2 635.63-773.2-17.62-2903.7 1433.33-4098.4 1182.18-973.58 2750.78-824.6 3821.98-446.7 0 0-110.1 381-997.6 880.1-364.7-687.8-1593.6-719.6-2298.7-237.6-1092.46 746.7-1222.24 2211.1-1285.66 3145.9-105.95 1559.2-1252.71 2105.3-1864.4 1456.5-330.24-350.2-550.67-652.5-762.65-760-188.02-95.3-500.5 10.6-670.19 293"
      transform="matrix(.01982 0 0 -.01982 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11404.4 9506.31c-720.7 0-1481.64 187.72-2136.79 727.19-871.71 717.6-976.71 1781.5-1061.05 2636.3-53.57 542.5-99.76 1011-316.47 1274.5-120.35 146.4-275.52 227.8-448.77 235.3-202.91 8.6-421.75-85.8-585.36-253-193.54-197.7-553.69-565.8-1003.32-387.1-314 124.9-485.16 387.3-482.07 738.9 4.87 545.4 467.76 1282.3 1234.02 1571.2 663.5 250.4 1362.53 173.7 1917.7-210.3 650.8-450.1 1009.26-1250.8 1009.26-2254.4 0-855.2 68.62-1716.4 566.35-2221.7 215-218.3 1047.7-874.6 2736.3-155.1 54.8 23.5 118.3-2.1 141.8-57 23.4-54.8-2.2-118.4-57.1-141.7-1814.2-773-2735.7-40.7-2974.89 202.2-552.36 560.8-628.59 1472.1-628.59 2373.3 0 930.6-325.25 1668.1-916.06 2076.6-495.92 343.1-1122.29 410.9-1718.53 186-670.57-252.9-1090.05-908.6-1094.19-1371.1-1.59-181.1 57.1-421.3 345.83-536 263.02-104.7 489.24 51.5 769.08 337.4 208.89 213.5 481.7 329.5 749.14 317.7 236.26-10.2 445.89-118.8 606.3-313.9 258.33-314.4 307.55-813.1 364.58-1390.5 84.64-857.7 180.56-1829.9 983.36-2490.8 1398.37-1151.36 3324.87-589.25 4148.17-131.2 52.2 29 118 10.3 146.9-41.8 29.1-52.2 10.2-117.9-41.9-147-485.8-270.28-1337.2-573.99-2253.7-573.99"
      transform="matrix(.02085 0 0 -.02085 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m14530.4 16153.6-69.5-530.5-35.3-270.2-610-4660s-308.6 61.1-716 177.1c-79.7 22.7-163.2 47.4-248.9 74.3-638.6 199.4-1399.5 512.6-1630 918.7-344.4 607.4 88.7 3119 690.6 4022.6 275.7 414.4 1248.1 411.9 1927.2 354.1 75.9-6.4 148.2-13.5 215.4-20.9 283.2-30.9 476.5-65.2 476.5-65.2"
      transform="matrix(.02003 0 0 -.02003 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m14922.5 16121.7-36.5-278.8-499.8-3818.2-129.7-990.5s-6.6 1.3-19.2 3.9c-3.6.8-7.9 1.7-12.4 2.6-3.6.7-7.6 1.6-11.6 2.5-3.1.5-6.2 1.3-9.3 1.8-1.2.3-2.6.5-3.8.9-5.7 1.2-12.1 2.5-18.8 4.1-1.4.2-2.9.5-4.5.9-5.5 1.2-11.2 2.5-17.4 3.8-8.3 1.7-16.9 3.7-25.9 5.8-6.4 1.6-13.3 2.9-20.2 4.5-7 1.6-14.2 3.2-21.5 4.9-9.3 2.1-19.1 4.4-29 6.5-20.2 4.8-41.6 9.7-64.1 15.1-19.9 4.5-40.8 9.6-62.6 15.1-25.1 6.2-51.5 12.5-78.7 19.3-100.8 25.4-215.7 55.7-339.8 91.1-24.1 6.8-48.7 13.9-73.5 21.1-17.3 5-34.9 10.3-52.7 15.6-42.7 12.7-86.6 26-130.7 39.9-659.2 205.8-1444.1 528.9-1681.7 948-292.8 516.1-41.7 2364.6 403.2 3522-247-1036.4-335.4-2181.7-114.7-2570.6 109.1-192.3 776.1 283.2 827.5 620.9 132.5 870.8 470.3 1959.3 1371.4 2472.8 23.5 13.3 469-133.4 856-165"
      transform="matrix(.01941 0 0 -.01941 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12957.1 15815.9c-527.9 0-1034.9-74.7-1200.9-324-558.9-839.1-985.8-3267.5-671.8-3821.1 296.4-522.5 1697.6-924.1 2347.2-1066.3l670 5118.7c-284.8 44.7-721.4 92.7-1144.5 92.7m666.1-5480-117.7 23.4c-89.1 17.6-2185.9 441-2616.7 1200.6-356.3 628.1 63.3 3130.3 680.2 4056.6 455.2 683.5 2451.7 350.7 2677.8 310.6l105.8-18.8-729.4-5572.4"
      transform="matrix(.02046 0 0 -.02046 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m13276.4 16266.7 1102.3-294.5c98.5-26.3 167.4-116.9 167.4-220.8 0-380.5-44.6-1259.4-445.5-1477.4-332.6-181-800.3-65.1-1034.8 226-262.4 325.8-143.6 1173.7-64.8 1589.7 24.5 129.4 150.6 210.3 275.4 177"
      transform="matrix(.01709 0 0 -.01709 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14973.3 16214.1c.2-391.6-45.7-1296.3-458.4-1520.6-342.6-186.5-823.9-67.1-1065.4 232.7-166 206.4-183.6 615.9-153.7 1000.2 18.4-198.3 72.7-366.5 156.1-485.9 477.9-683.9 1030.9-86.1 1030.9-86.1s45.8-178.8 104.1-199.3c243.4-85.8 333.3 724.5 370.3 1144.9 10.5-26.6 16.2-55.5 16.1-85.9"
      transform="matrix(.0166 0 0 -.0166 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13121.4 16019.6c-15.7 0-31-4.5-44.7-13-21.5-13.6-36.1-35.3-41-61.3-136.7-721.5-122.9-1270.7 37-1469.2 199.9-248.2 593.4-335.5 859.2-190.9 274.6 149.4 372.4 794.8 372.4 1349.1 0 42.5-27.6 79.6-67 90.1l-1094 292.3c-7.3 1.9-14.6 2.9-21.9 2.9zm519.3-2071.2c-290.8 0-589.8 128.8-776.1 360.1-275.1 341.4-201.6 1105.5-91.5 1686.4 18.6 98.4 77.3 185.1 161.2 237.8 82.6 52 183.9 67.3 277.9 42.2l1094.1-292.3c156.2-41.7 265.3-185 265.3-348.3 0-487.5-66.6-1341.5-511.9-1583.8-127.2-69.2-272-102.1-419-102.1"
      transform="matrix(.01721 0 0 -.01721 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14083.7 14968.1c471.4 169.8 650 831.2 664.9 1287.1-3.3 21.8 12.2 42.7 34.5 43.8 19.1 1 35.6-12.6 38.7-31 12.7-77.6 11-145.2 9.1-217.1-22.3-430.2-117.9-897.4-463.9-1190.3-70.3-58.4-141.7-106.8-231.6-137.2-181.9-55.2-240.8 221.7-51.7 244.7"
      transform="matrix(.01636 0 0 -.01636 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13445.3 15880.5c-424.9 98.8-868.8 200.7-1305.6 186.6-153.4-6.2-168.7 219-15.1 232.9 356-10.4 692.1-105.4 1030-201.2 109.2-32.5 217-66.2 325.4-103.3 31.4-10.7 48.2-44.9 37.5-76.3-10.3-30.1-42.1-46.7-72.2-38.7"
      transform="matrix(.01974 0 0 -.01974 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#40d2ff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11967.5 13358.7c481.7-1840.1-619.6-3722.27-2459.66-4203.89-1840.07-481.66-3722.21 619.58-4203.88 2459.69-481.62 1840.1 619.62 3722.2 2459.69 4203.9 1840.1 481.6 3722.25-619.6 4203.85-2459.7"
      transform="matrix(.03469 0 0 -.03469 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#8ce4ff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m5518.8 11937.7-3.37 12.7c-484.54 1851.2 627.33 3751.5 2478.47 4236.1 293.59 76.8 588.01 113.5 878.18 113.5 593.71 0 1168.22-153.8 1673.32-432.1-462.3 217-972.95 335.2-1498.95 335.2-296.51 0-597.9-37.6-898.15-116.2-1816.15-475.3-2931.75-2278.7-2578.11-4095.9-18.04-16.7-35.17-34.5-51.39-53.3"
      transform="matrix(.03376 0 0 -.03376 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#47a8cc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8493.53 10102.9c-95.22 25.9-189.33 55.5-281.88 88.8 11.07-1.1 22.29-2.2 33.5-3.2 85.95-12.6 171.61-22.6 256.78-30-2.62-18.6-5.39-37.1-8.4-55.6m-1632.31 936.3c-70.16 68.9-137.81 140.8-202.53 215.5 64.82 1.7 126.54 8.1 182.92 16.9-5.98-79.4 1.94-158.3 19.61-232.4M12788 12205.5c-12.4 70.6-34.4 141.4-67.9 208.1 50.9 11.1 104.3 25.5 158.3 44.3-27.2-85.3-57.3-169.4-90.4-252.4m-402.7 1891.1c-73.5 304.4-205.7 590.6-387.9 846.6 54.3 441.6 30.8 899.9-81.3 1356.8 475.7-462.7 835.4-1057 1015.8-1745.9 37.1-142 65.7-284.1 85.9-426-85.1 35.6-176.4 53.8-271.7 53.8-44.7 0-90.2-4-136.4-12-87.3-15.1-161.4-40.7-224.4-73.3"
      transform="matrix(.03168 0 0 -.03168 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11858.2 12174.1c2160.8 344.8 3025.1 2030.7 3657.3 1840 358.4-108.1 784.5-709.1 778.4-856.8-18.6-447.2-779.3-1150.1-1441.2-1536.6-1199.8-700.6-2406.8-700.6-2406.8-700.6l-587.7 1254"
      transform="matrix(.0278 0 0 -.0278 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16294 13157.3c-.2-2.5-.2-5.2-.6-7.7-26.7-447.3-782.4-1144.5-1440.7-1528.8-797.3-465.7-1597.8-621.8-2045.2-674.2-225.6-26.4-361.5-26.4-361.5-26.4l-587.7 1253.9c2160.7 345 3025 2030.7 3657.3 1840 358.4-108.2 784.4-709.2 778.4-856.8"
      transform="matrix(.0278 0 0 -.0278 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 13155c-26.7-447.5-782.7-1145-1441.2-1529.5-797.7-465.9-1598.5-622.1-2046.1-674.4l103.5 333.3s1143.5 118.9 1941.9 744.2c727.7 570 553.7 1280.7 569.4 1263.3 10.1-11.3 518.9-86.2 872.5-136.9"
      transform="matrix(.0278 0 0 -.0278 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12383.1 10784.4c-44.5 0-80.5 36.1-80.6 80.6 0 44.5 36.1 80.5 80.6 80.6 11.8 0 1194.3 9 2354 686 659.8 385.4 1377.5 1065 1394 1462.6.8 108.2-389.9 673.2-717.2 772-220 66.4-506.4-164.6-902.8-484.1-599.4-483.1-1420.2-1144.8-2700.1-1349.1-43.9-6.9-85.2 23-92.3 66.9-7 44 23 85.3 66.9 92.3 1237.5 197.5 2039 843.6 2624.3 1315.4 434.9 350.6 749 603.9 1050.6 512.9 391.4-118.1 839.5-742.7 831.6-932.9-21.5-519.3-874.8-1245.4-1473.8-1595.1-1198.8-700-2385.3-708.1-2435.2-708.1"
      transform="matrix(.02795 0 0 -.02795 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16227 12654.7c-3.3 0-6.7.3-10.1.8-1110 164-1059.2 1622.9-1058.6 1637.6 1.7 37.7 33.7 67.2 71.2 65.2 37.7-1.5 67-33.4 65.3-71.1-.1-3.5-13.3-347.9 100-704.5 147.4-464.8 430.8-731.3 842.1-792.1 37.3-5.5 63.1-40.2 57.6-77.5-5-34-34.2-58.4-67.5-58.4"
      transform="matrix(.02526 0 0 -.02526 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15385.9 12427.6c173.8-14.1 303.1 50.4 385.1 117.6 0 0 529 127 494.7 360.2-22.9 156.3-60.2 1304.2-1128.3 1048-273.1-65.5-458.3-391.6-393.8-747.8 78.3-433.1 401.4-758.4 642.3-778"
      transform="matrix(.0288 0 0 -.0288 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16283.7 12919.7c16.3-110.9-94.7-197.8-215.7-258.4-133.6-67.2-279.7-102.3-279.7-102.3-82.1-67.2-211.4-131.8-385.6-117.7-185.6 15.2-420.3 212.1-554.1 500.2-39.8 85.6-70.7 179.3-88.7 278.8-64.6 356.4 120.8 682.8 394.1 748.4 1069.4 256.7 1106.7-892.7 1129.7-1049"
      transform="matrix(.02877 0 0 -.02877 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 12844c-135.5-68.1-283.7-103.7-283.7-103.7-83.3-68.1-214.5-133.7-391.2-119.4-165.1 13.5-368.5 168.8-507.6 403.9 41.2-39.8 217-93.6 306.6-6.3 77.4 75.5 26.1 225.7 68.8 455.6 32.1 171.9 221.4 131.8 221.4 131.8l585.7-761.9"
      transform="matrix(.02836 0 0 -.02836 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15319.2 12410.8c110.4 5 213.4 40.3 295.8 111.3 19.6 17.2 40.1 36.7 65.8 43.5 119.1 31.8 433.9 131.9 432.5 268.2-3 24.9-12.3 107.6-15.3 134-38.6 295.1-129.2 623-391.7 789-191.8 116.8-434.1 105.6-647.2 47.8-177-60.4-283-239.7-309-421.6-17.6-103.5-169.1-82.2-157.4 22.2 29.6 215.8 156.1 434.7 362.8 529.2 66.9 30.8 138.6 42.9 210 55.7 136.6 21.7 281.2 23.5 422.4-12.2 439.9-110.9 622.4-582.4 667.9-990.2 1.8-15.8 12.1-107.7 13.7-121.6 30.5-209.9-194-325.7-354.2-391-64.9-25.6-128-46.4-197.4-64.2l31.8 15.9c-28.8-27-66.2-43-101.6-59.2-104.5-45.5-226.2-57.4-335.3-36-21.7 4-36 24.9-31.9 46.6 3.5 18.9 19.9 32.2 38.3 32.6"
      transform="matrix(.02894 0 0 -.02894 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdd907",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15427.1 14384.2s-32.9 37.4-90.4 76.5c-43.4 29.5-100.8 60-168.5 76.5-9.9 1.7-19.6 2.2-29 1.4-47.6-4.1-86.4-40.6-92.5-92.2l-22.1-236.1s-342.8-13.2-473.2-312.2c-101.9-233.4-171.5-659.4 80.1-1173.8 35.6-72.9 74.8-141.2 117.5-204.8 142.1-211.8 324.2-371.9 550.4-476.8 199.1-92.3 423.6-125.2 605.3-118.6 135.5 4.9 247.2 31.6 307 71.9 48.2 32.4 70.8 67.5 76.6 101.6 11.7 67.5-43.8 130-96.9 154.9-46.2 21.6-149.8 80.5-269.1 175.7-18.2 14.4-36.6 29.7-55.4 45.9-144.6 124.8-302.4 299.5-406.3 522.4-9.7 20.8-18.9 42.1-27.6 63.8-100.9 251.2-122.3 738.2-163.2 926.6-5.1 23.7-10.5 42.7-16.4 55.8 0 0 149.6 159.3 179.5 192.5 48 53.4 31.2 104.8-5.8 149"
      transform="matrix(.02989 0 0 -.02989 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdd907",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16191.5 12252.6c53.1-24.9 108.5-87.4 96.8-155-5.7-34-28.3-69.1-76.4-101.5-59.9-40.3-171.6-67-307.1-72-181.7-6.4-406.2 26.4-605.3 118.8-226.2 104.8-408.4 265-550.5 476.7-42.7 63.6-81.8 131.8-117.4 204.7-38.4 78.6-69.3 155.1-93.8 229-135.9 411-72.7 747.1 13.6 944.8 130.4 299.1 473.1 312.2 473.1 312.2l22.3 236.2c6.2 51.5 44.9 88.1 92.5 92.3 9.4.7 19 .2 29-1.5 67.7-16.5 125-46.9 168.4-76.5 23.8-16.1 43.2-31.8 57.9-44.9 21.1-18.7 32.5-31.6 32.5-31.6 37.1-44.1 53.8-95.6 6-149-30-33.2-179.5-192.4-179.5-192.4 5.8-13.4 11.2-32.2 16.3-55.9 41-188.4 62.4-675.4 163.2-926.6 8.7-21.7 18-43 27.6-63.8 103.9-222.9 261.7-397.7 406.2-522.3 18.9-16.3 37.3-31.6 55.5-46 119.4-95.3 222.8-154.1 269.1-175.7"
      transform="matrix(.02989 0 0 -.02989 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdd907",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16191.5 12252.6c53.1-24.9 108.5-87.4 96.8-155-5.7-34-28.3-69.1-76.4-101.5-59.9-40.3-171.6-67-307.1-72-181.7-6.4-406.2 26.4-605.3 118.8-226.2 104.8-408.4 265-550.5 476.7-3.3 5.1-6.9 10.1-10 15.3-39 59.2-74.6 122.4-107.4 189.4-38.4 78.6-69.3 155.1-93.8 229-57.8 174.7-79.6 335.8-78.7 478.8.8 193 42.8 352.3 92.3 466 130.4 299.1 473.1 312.2 473.1 312.2l22.3 236.2c6.2 51.5 44.9 88.1 92.5 92.3 9.4.7 19 .2 29-1.5 67.7-16.5 125-46.9 168.4-76.5 23.8-16.1 43.2-31.8 57.9-44.9 21.1-18.7 32.5-31.6 32.5-31.6 37.1-44.1 53.8-95.6 6-149-30-33.2-179.5-192.4-179.5-192.4 5.8-13.4 11.2-32.2 16.3-55.9 15.4-70.4 27.9-182.3 42.9-308.1 25.1-211.1 57.2-461 120.3-618.5 8.7-21.7 18-43 27.6-63.8 96.6-206.9 239.6-372.6 375.2-495 10.4-9.3 20.8-18.5 31-27.3 18.9-16.3 37.3-31.6 55.5-46 119.4-95.3 222.8-154.1 269.1-175.7"
      transform="matrix(.02989 0 0 -.02989 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdd907",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14537.8 12953.2c24.4-73.8 55.3-150.4 93.8-228.9 32.7-67.1 68.5-130.3 107.3-189.3 3.2-5.2 6.8-10.4 10.1-15.4 142.1-211.8 324.3-372 550.4-476.9 35.1-16.3 71.2-30.7 107.6-43.3 169.5-59.3 348.2-80.6 497.8-75.3 135.5 4.8 247.2 31.7 307 72 48.1 32.3 70.7 67.4 76.5 101.5 11.7 67.7-43.8 130-96.9 155.1-29.7 13.8-83 43.1-148.9 87.4-36.7 24.6-77.4 54.1-120.1 88.2-18.3 14.3-36.7 29.6-55.5 46-10.2 8.8-20.7 17.9-31.1 27.2-135.5 122.4-278.6 288.2-375.2 495-9.5 21-18.8 42.1-27.6 63.8-63.1 157.5-95.2 407.6-120.2 618.7-15.1 125.6-27.6 237.5-42.9 307.9-5 23.7-10.5 42.6-16.3 56 0 0 149.4 159.1 179.4 192.4 47.9 53.3 31.2 104.8-6 148.9 0 0-11.2 12.9-32.4 31.8-14.7 12.9-34.2 28.6-57.9 44.8-43.4 29.6-100.8 59.9-168.5 76.5-10 1.7-19.5 2.1-28.9 1.4-47.6-4.1-86.4-40.8-92.5-92.3l-22.2-236s-342.8-13.3-473.2-312.2c-49.5-113.8-91.6-273.2-92.4-466.1-.8-143.1 20.9-304.1 78.8-478.9"
      transform="matrix(.02989 0 0 -.02989 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15407 11999.4c169.5-59.3 348.2-80.6 497.8-75.3 135.5 4.8 247.2 31.7 307 72 48.1 32.3 70.7 67.4 76.5 101.5 11.7 67.7-43.8 130-96.9 155.1-29.7 13.8-83 43.1-148.9 87.4-10.7-73.5-64-180.8-276.9-105.7 5.6-5.8 156.7-160.2 1.1-214-148.5-51.5-306.1-30.6-359.7-21"
      transform="matrix(.02989 0 0 -.02989 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 12868.1c-80.6-9-169-22.5-263.4-42.1-244.5-51.1-412.1 52-414 302.4-50.2-14.7-117.1 54-139.1 117.4-95.7-60.6-173.2-90.7-173.2-90.7 14.8-86.9-61.6-182.5-139.4-252.8-40 60.9-76.7 126-110.5 195-39.5 80.9-71.3 159.6-96.5 235.7-59.5 179.8-81.9 345.6-81 492.7 203.7 58.6 558.5 113.7 878.7 254.2 25.8-217.3 58.9-474.6 123.8-636.7 9-22.4 18.5-44.2 28.4-65.7 99.4-213 246.7-383.5 386.2-509.4"
      transform="matrix(.02904 0 0 -.02904 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fef4b4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16233 14806.7c-5.8 37.1-10.3 73.5-13.5 109-4.2 47.5-6.1 93.6-6.2 138-.1 197.4 38.2 363.8 86.7 489.2-21.1-54.4-40.2-116.6-55.1-185.6-18.8-87.9-30.9-186.9-31.4-295.4v-9.4c0-77.4 6-159.5 19.5-245.8"
      transform="matrix(.02665 0 0 -.02665 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fef4b4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15337.9 14239.6c0 3 .1 6 .1 8.9.4 102.7 11.8 196.4 29.7 279.4-17.9-83-29.3-176.7-29.7-279.3v-.1c0-2.9-.1-5.9-.1-8.9m192.4 648.2c94.8 102.1 215.8 148.4 301.3 169.3-85.5-20.9-206.5-67.2-301.3-169.3m407.5 186.4 23.7 250.4c6.5 54.7 47.6 93.5 98.1 97.9 2.9.2 5.9.3 8.8.3 4 0 8.1-.1 12.3-.6-4 .4-7.9.6-11.8.6-3.2 0-6.2-.1-9.3-.4-50.5-4.3-91.6-43.2-98.1-97.8l-23.6-250.4h-.1m362.2 243.1c-6.6 5.1-13.5 10.3-21 15.6 7.5-5.3 14.4-10.5 21-15.6m-160.5 88.9c-12.7 4.5-25.8 8.6-39.3 12.3 13.5-3.7 26.6-7.8 39.3-12.3"
      transform="matrix(.02817 0 0 -.02817 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fef4b4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15309.5 14222v.2c.4 102.3 11.8 195.8 29.6 278.7 14.1 65.2 32.1 123.9 52 175.3 5.2 13.6 10.7 26.8 16.1 39.3 25.2 57.7 57.8 105.3 94.2 144.6 94.7 102 215.5 148.2 300.8 169.1 62.6 15.3 106 16.9 106 16.9v.1l23.6 249.9c6.5 54.5 47.6 93.4 97.9 97.7 3.1.2 6.2.4 9.3.4 3.9 0 7.8-.3 11.8-.6 3.2-.3 6.4-.8 9.6-1.3 3.3-.8 6.6-1.6 9.9-2.5 13.5-3.6 26.5-7.7 39.2-12.3 50.6-18 94.2-42.3 129.2-66.2 3.5-2.3 6.8-4.6 10-6.9 7.5-5.3 14.5-10.5 21-15.5 11.6-9 21.7-17.5 30.3-25.2-61.8 21.7-134.3 42.1-187.9 42.1-38.7 0-67.6-10.6-75.8-38.9-62.6-216.5 8.9-340.1 8.9-340.1s-19.8 3.7-52.7 3.7c-88.5 0-271.5-26.6-414.1-223.6-77.4-107-101.4-276.8-102.1-442.7-62.2-14.6-118.6-28.3-166.8-42.2"
      transform="matrix(.02823 0 0 -.02823 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fde7b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16157.8 14396.8c-32.1 97-54.2 190.3-68.2 279.1-13.4 85.5-19.3 166.9-19.3 243.6v9.4c50.7 14.6 109.9 28.9 175.2 44.2-1.1-265.4 54.5-521.5 54.5-521.5l-142.2-54.8"
      transform="matrix(.0269 0 0 -.0269 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fcd886",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16270.2 13184.3c-4.7 0-9.8 1.6-14.7 5.3-85.3 65.3-194.5 172-276.1 332-157.8 309.2-122.5 527.4-78.9 641.9 3.7 9.7 11.6 14 19.8 14 12.3 0 25.4-10 25.5-26.2.9-430.8 209.1-762.1 340.2-928 14-17.8 1.3-39-15.8-39"
      transform="matrix(.02864 0 0 -.02864 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fef4b4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16274 15103.5c-28.5 0-71 18.5-71 18.5s3.3-.3 8.4-.3c16.7 0 52.5 2.4 53.5 22.4 1.1 20.7-28.6 56.4-28.6 56.4s61-48.7 62.7-76.5c1-15.4-10.1-20.5-25-20.5"
      transform="matrix(.0283 0 0 -.0283 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f4dd7a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m16300 13814-.1.2-91.8-61.7 91.9 61.5"
      transform="matrix(.02725 0 0 -.02725 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f4dd7a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16299.8 12771.1c.2.8-1.6 3.2-5.5 6.9 3.4-5.2 5.3-7.6 5.5-6.9"
      transform="matrix(.02913 0 0 -.02913 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m16299.6 14946.2.1.4c.3.3.3.1-.1-.4"
      transform="matrix(.02805 0 0 -.02805 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14977.9 14166.1h.2zm2.3-57.5c-13.1-.6-307.9-16.6-421.4-276.8-66.6-152.6-192.2-567.5 78.8-1121.8 149.5-305.8 364.3-525.3 638.5-652.4 330.6-153.4 732.4-132.4 853-51.2 36.8 24.7 54.4 50.2 52.4 75.8-2.5 32.3-35 66.1-64.9 80.1-153.8 71.9-594.7 362.2-785 836-69.4 172.8-101.9 446.5-127.9 666.4-15.7 132.8-32 270.1-50.2 310.7l-15.7 35.1 26.2 27.9c1.5 1.6 149.2 158.8 178.2 191.1 15.8 17.6 25.3 34.1-7.2 73.1 0 0-90.4 99.6-225.8 133-16 2.6-27.2-2.7-33.9-7.6-10.1-7.5-16.6-19.3-18.3-33.2l-26.5-284.3zm832.7-2280.2c-185.1 0-399.4 38.6-585.3 124.8-298.7 138.5-532 376.1-693.3 706.2-292.2 597.5-154 1051.1-81 1218.4 112.8 258.7 363.5 324.1 471.6 340.4l17.7 188.6c5.5 47.3 28.4 87.3 64.4 113.8 35.7 26.3 79.9 36.2 124.6 27.9l2.9-.7c172.8-41.7 283.2-165.1 287.9-170.3 63.9-76.6 65.5-158.2 5.3-225-22.8-25.4-112.7-121.3-156.1-167.7 16.7-60.8 28.8-158.6 46.4-306.5 25.2-213.2 56.6-478.4 120.4-637.1 209.2-520.9 721.8-772.3 727-774.7 60.5-28.3 124.4-93.5 130.9-175.4 3.7-48-11.1-118.4-102.9-180.2-78.6-52.9-218.3-82.5-380.5-82.5"
      transform="matrix(.02998 0 0 -.02998 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16152.1 15005.7c-93.9-438.5-27-898.3 136.1-1312.2 11.8-29-29.8-49.9-45.5-22.2-230.3 402.1-288.6 909.6-130.1 1348.1 9.6 24.1 47 11.9 39.5-13.7"
      transform="matrix(.02784 0 0 -.02784 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15822.6 12699.9c107.3-60.2 235.5-139.1 342.5-200.3 37.9-22.5 75.8-45.1 113.1-70.9 21.8-14.6 6.5-50-19-44.3-90.3 19.3-174.4 59.1-252.3 107.6-77.6 49.1-150.3 106.7-209.6 177.2-14.3 16.4 6.8 41.6 25.3 30.7"
      transform="matrix(.02927 0 0 -.02927 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16285.7 15108.8c-114.5 7.6-319.5 88.9-363.4 206.1-17.8 50.1 19.4 105.1 65.4 123.6l19.6 8.2c31.4 13.3 67.7-1.5 80.9-33 15.9-36.5-9-79.7-48.3-84.7-3.7-.6-11.3-1.8-14.9-2.4-2.4-.5-2.3.3-1 5 19.7-66.6 167.5-177.9 264.1-202.5 11.9-2.2 9.7-21-2.4-20.3"
      transform="matrix(.02824 0 0 -.02824 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15490.6 13442.7c-237.1 489.1-163.7 940.6 148.4 1086.7 331.1 154.9 661-221.3 572-377.6-97.2-170.8-362.6-102.8-371.2-109.4-8.6-6.5 151-281.1 12.5-447l-361.7-152.7"
      transform="matrix(.02762 0 0 -.02762 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16277.2 14209.5c-97.6-171.3-364.2-103.1-372.7-109.8-8.7-6.6 151.6-282.2 12.5-448.8l-363.1-153.2c-238.1 491-164.6 944.4 148.9 1091 247.6 115.8 494.5-63.9 567.4-228.2 25.3-56.2 29.8-110.9 7-151"
      transform="matrix(.0275 0 0 -.0275 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16277.2 14209.5c-97.6-171.3-364.2-103.1-372.7-109.8-8.7-6.6 151.6-282.2 12.5-448.8 0 0-10.7 217.7-62.1 323.1-51.3 105.3-158.6 182.2-123.3 245.5 35.3 63.4 211.7-98.2 440.3 10 57.9 27.4 86.3 76.7 98.3 131 25.3-56.2 29.8-110.9 7-151"
      transform="matrix(.0275 0 0 -.0275 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16259.6 14213.4c-43.7 160.8-291.6 366.9-539.7 250.7-281.6-131.8-353.4-532.6-155.3-974.2l-38-15.9c-237.7 490.1-164.3 942.7 148.6 1089.1 313.1 146.5 624.8-180.4 584.4-349.7"
      transform="matrix(.02755 0 0 -.02755 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15239 13701.3c-80.4 215.8-70.2 477.5 80.2 668.8 113.6 149.8 326.9 237.3 514.2 180 199.6-48.9 466.6-306.6 352.9-527.2-88.9-155.1-287.7-170.3-439.3-146.4 18.6.1 35 5.5 50.9 18.2 27.9 23.6 34.4 54.4 27.5 82.7 17.2-41.7 39.3-90.6 52.8-136.7 26.5-86.1 31.3-196.8-18.6-280.7-24.1-41-88.2-114.4-129.9-56.1-6.9 11.8-7 24.8-3.6 37.1 26.8 131.8-10.2 255.7-56.3 379.2-3.1 9.8-5.7 16.6-6.9 27.7-6.5 55.3 40.9 101.5 99.3 93.5 78.1-9.7 185.8-16.8 247.6 31.5 12.5 9.7 22.4 21.3 30.5 35.2 13 39.3-14 85.6-43.5 127.4-101.6 137.3-298.5 206.3-447.9 110.8-211.1-126.8-231.3-400-228.6-629.7.7-46.5-65.3-58.4-81.3-15.3"
      transform="matrix(.02778 0 0 -.02778 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16016 14330.6c-164.3 13.2-433.1-187.3-307.8-393.2 90.4-148.6 244.6-56 419.2-40.2 172.6 80.3 112 415.5-111.4 433.4"
      transform="matrix(.0286 0 0 -.0286 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16276.3 14192.6c23.7-97.9-3.7-205.3-92.1-246.3-175-15.9-329.8-108.9-420.6 40.3-13.3 21.7-22.1 43.6-27.1 65.1-42.8 181.6 188.6 341.3 336 329.5 110.1-8.8 180.8-94.3 203.8-188.6"
      transform="matrix(.0285 0 0 -.0285 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16298.1 14211.6c.8-3 1.5-6.2 1.9-9.3-35.4 59.7-93.9 104.9-172.4 111.1-143.1 11.5-365.2-138.4-339.4-313.2-1.4 1.7-2.4 3.3-3.4 5.1-13.4 21.8-22.2 43.7-27.2 65.2-42.8 181.9 188.9 341.8 336.5 329.9 110.2-8.8 181-94.3 204-188.8"
      transform="matrix(.02846 0 0 -.02846 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16276.3 14192.6c23.7-97.9-3.7-205.3-92.1-246.3-175-15.9-329.8-108.9-420.6 40.3-13.3 21.7-22.1 43.6-27.1 65.1 41.2-23.7 101-43 158.5-39.1 92.3 6.4 222.1 31.4 305.8 96.2 41.3 31.9 66 58.1 75.5 83.8"
      transform="matrix(.0285 0 0 -.0285 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15974.1 14227.6c-68 0-172.7-53.3-220.4-130.8-29.8-48.3-30.6-94.2-2.5-140.3 33.4-54.9 70.1-53.7 200.9-25.9 38 8 80.5 17.1 124.9 22.2 39.8 26.1 49.2 85.2 40.2 135.2-10.8 60.2-52.3 132.7-133.7 139.3-3.1.2-6.2.3-9.4.3zm-143.8-475.9c-80 0-158.3 26.1-216.3 121.3-59.9 98.4-58.8 207.8 3 308 77.9 126.5 244.6 217.1 379.3 206.3 161.5-13 256.4-145.4 278.9-270.9 24.8-137.6-31.6-264.1-140.2-314.6-8.4-3.9-17.4-6.3-26.6-7.2-41-3.7-82.7-12.5-122.9-21.1-48.8-10.4-102.4-21.8-155.2-21.8"
      transform="matrix(.02865 0 0 -.02865 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16056.1 13822c-161.5 15.8-426.3-173.5-313.4-376.5 88.9-159.7 332.7-42 434.8-29 122.5 88.9 111.2 382.6-121.4 405.5"
      transform="matrix(.02879 0 0 -.02879 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16293 13608.3c-4.2-65.3-31.2-126.6-76.9-159.7-102.3-13.1-346.6-131-435.8 29.1-12.3 22.3-20.2 44.4-24.2 66-31.9 176.5 194.3 325.4 338.2 311.3 146.8-14.4 205.7-136.2 198.7-246.7"
      transform="matrix(.02872 0 0 -.02872 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 13686.6c-31.7 50.4-84.8 88.6-162.9 96.2-142.7 14.1-366.4-132.2-339.2-306.8-2.2 3.5-4.4 7.2-6.6 11.1-12.3 22.3-20.2 44.5-24.2 66.1-32 176.6 194.4 325.6 338.4 311.5 116.7-11.5 177.8-90.7 194.5-178.1"
      transform="matrix(.0287 0 0 -.0287 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 13614.1c-4.2-65.3-31.2-126.6-76.9-159.8-102.3-13-346.8-130.9-436 29.2-12.3 22.3-20.2 44.5-24.2 66.1 31.2-23.4 72.5-43.9 119.4-40.6 132.5 9.1 264.2 142.4 417.7 105.1"
      transform="matrix(.0287 0 0 -.0287 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15980.9 13695.1c-66.3 0-167.8-49.3-215.7-122.4-30.2-46.1-32.7-90.4-7.7-135.3 35.9-64.5 138.2-42.9 258.4-10.4 25.6 6.9 48.7 13.2 69.8 17.5 29.4 32.3 41.2 95.6 26.4 150.5-11.2 41.2-41 91.9-119.7 99.5-3.7.4-7.6.6-11.5.6zm140.2-325.3h.3zm-276.1-133.4c-87.7 0-173.4 27.9-226.6 123.5-54.2 97.5-49.4 204 13.6 300.1 79.5 121.1 244.9 206 375.9 193.1 128.1-12.6 224.5-93.5 257.9-216.4 34.2-126.1-7-265.4-97.9-331.4-10.8-7.8-23.4-12.8-36.7-14.5-16.9-2.2-44.5-9.6-73.7-17.5-59-16-136.5-36.9-212.5-36.9"
      transform="matrix(.02889 0 0 -.02889 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16001.8 13339.1c-147-12.4-320.7-168.2-228.2-342.4 85.5-160.6 320.5-104.9 419.1-51.1 107.3 58.5 25.4 411.7-190.9 393.5"
      transform="matrix(.02906 0 0 -.02906 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16280.5 13171.6c19.5-81.3 8.2-161.1-38-186.2-99-53.9-334.7-109.8-420.4 51.3-2.7 5.2-5.2 10.3-7.3 15.4-75.3 168.4 93.2 316 236.2 328.1 123.8 10.3 203.7-100.6 229.5-208.6"
      transform="matrix(.02897 0 0 -.02897 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m16299.8 13187.3.2-.5c-39.8 71.8-106.3 127.2-195.4 119.7-126.8-10.8-273.7-127.9-253.2-271.7-3.7 5.5-7.2 11.4-10.4 17.4-2.7 5.2-5.2 10.3-7.4 15.4-75.4 168.7 93.3 316.4 236.5 328.5 123.9 10.4 204-100.7 229.7-208.8"
      transform="matrix(.02894 0 0 -.02894 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16280.5 13171.6c19.5-81.3 8.2-161.1-38-186.2-99-53.9-334.7-109.8-420.4 51.3-2.7 5.2-5.2 10.3-7.3 15.4 102.4-17.5 273.5-14.9 465.7 119.5"
      transform="matrix(.02897 0 0 -.02897 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15974.8 13232.3c-64.3-5.4-135.5-51-165.5-106.1-21.3-39.2-21-78.4.9-119.7 15-28.2 37.2-45.9 69.9-55.7 75.9-22.7 184.2 6.9 238.6 35.8 10.8 15.7 16 87.5-22.2 161.1-17.6 34-55.7 90.2-121.8 84.6zm-33.5-447.3c-36.6 0-72.9 4.4-106.7 14.5-72.6 21.8-129.2 67.7-163.8 132.8-46.8 88.1-46.9 183.8-.2 269.4 55.9 102.6 172.8 178.1 290.9 188.1 137.5 11.4 257.4-84.2 305.3-243.8 33.2-110.6 24.3-245.4-70.3-297-64.8-35.3-161.4-64-255.2-64"
      transform="matrix(.02912 0 0 -.02912 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15912.2 14351.3c73.7-50.3 171.9-69.5 260.4-57.6 127.4 6.6 122.4-182.1-4.6-168.7-122.2 18.3-237.4 72.4-317.6 168.9-32.2 41.3 23 91.3 61.8 57.4"
      transform="matrix(.02728 0 0 -.02728 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15547.2 15461.1s717.3-285.5 729.4-595.2c23.4-597.9-770.8-417.5-770.8-417.5s589.7 271.8-62.3 681.2c-76.3 48 187 239.9 103.7 331.5"
      transform="matrix(.02237 0 0 -.02237 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15386.6 15308.1h.4zm86.2-209.4c206.6-142.9 306.2-293.4 296.2-448-5.2-79.8-38.2-146.8-80.8-201.3 111.6 1.9 231.8 22.7 308.5 86.6 63.9 53.3 92.9 133.7 88.5 245.9-5.4 138.8-286.2 325.5-531.4 442.9-19.4-38.5-44.2-73.8-64.3-102.2-5-7.1-10.9-15.5-16.7-23.9zm197-857.1c-145.3 0-260.9 25.5-270.5 27.7-43.3 9.8-75.7 46.2-80.3 90.5-4.7 44.3 19.4 86.6 59.9 105.3 45.2 21.2 176.8 103.9 182.6 199.4 5.1 83.7-85.9 188.7-256.3 295.7-31.6 19.8-52.9 50.4-60 86.2-14.1 70.7 28.9 131.7 74.5 196.2 15.9 22.6 43.7 61.9 53.2 84.5-19.1 34.1-17.8 76.9 5.2 110.2 27.7 39.7 79 55.2 124 37.3 129.6-51.5 777-327 790.9-684.6 7-178.8-48-318.1-163.7-414.2-129.1-107.2-310-134.2-459.5-134.2"
      transform="matrix(.0225 0 0 -.0225 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13480.2 16300s-771.3-211.8-795.8-525.4c-13.4-171.4 37.9-301.3 71.7-340.7 219.6-255.7 679.3-229.3 679.3-229.3s-611.6 345.1 127.3 728.8c86.5 45-180.2 275.1-82.5 366.6"
      transform="matrix(.02069 0 0 -.02069 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#5e3519",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13267.4 16025.2c-259.4-91-555.6-244.3-566-377.3-10.8-137.9 29.1-236.5 44.9-256.8 72.6-84.6 183.3-131.5 288.9-157.3-29.9 54.8-49.6 118.1-48.4 188.8 2.9 167.3 123.3 320.4 358.1 455.9-5.5 9.5-11.1 19-15.9 27.1-19.7 33.4-44.2 74.9-61.6 119.6zm30.3-1047.4c-129.5 0-511.2 21.9-722.1 267.5-57.7 67.3-113.1 227.5-98 420.1 29 371.1 731.6 582.3 872.1 620.9 49.9 13.7 102.5-8.4 127.6-53.5 21.1-37.5 18.5-83-4.8-117.5 8.3-25.2 34.7-69.9 49.9-95.6 43.3-73.5 84.2-142.8 62.8-217.7-10.9-37.8-36.5-68.8-72.2-87.3-193.1-100.3-300.2-205.3-301.8-296-1.8-106.2 140.6-208.5 178.9-230.1 43.3-24.5 65.4-74.6 54.2-123.1-11.3-48.6-53.2-83.8-103-86.7-3.3-.2-18.9-1-43.6-1"
      transform="matrix(.02084 0 0 -.02084 -53.725 554.908)"
    />
    <g
      clipPath="url(#a)"
      transform="matrix(.01538 0 0 -.01538 -53.725 554.908)"
    >
      <path
        style={{
          fill: "url(#b)",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M11866.5 5556.6c-580.7 0-1199.3 308.4-1702.9 559.5-244.76 122-453.21 225.9-627.72 282 173 203.2 476.52 581.2 612.82 882.7 180 398.5 281.5 517.5 416.5 608.4 39.7 26.8 58 75.8 45.6 122.1-12.4 46.3-52.8 79.5-100.6 82.8-11.2.8-22.5 1.7-33.8 2.7 93.9 90.9 211.7 206.3 299.9 296.6 219.8 224.9 549.5 562.2 1162.5 562.2 212.9 0 460.1-40.7 748.6-141 7.8-2.3 592.1-184.5 925.2-569.2 31.4-126.5 71.6-260.9 122.3-402.8 31.6-88.2 64.5-171.6 98.3-250.4-41.4-484.8-363.6-1152.1-366.3-1157.8-16.7-32-420.3-784-1469.2-872.4-43.5-3.7-87.2-5.5-131.2-5.4"
      />
    </g>
    <path
      style={{
        fill: "#512717",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M953.588 639.81c17.451-5.61 38.296-16 62.772-28.2 54.17-27.02 121.65-60.66 183.41-55.41 104.89 8.84 145.25 84.04 146.92 87.24.32.66 44.28 91.73 36.04 138.38-11.97 67.84-112.97 99.33-113.99 99.64-111.92 38.9-161.5-11.82-191.11-42.12-8.82-9.03-20.6-20.57-29.99-29.66 1.13-.1 2.26-.19 3.38-.27 4.78-.33 8.82-3.65 10.06-8.28 1.24-4.63-.59-9.53-4.56-12.21-13.5-9.09-23.65-20.99-41.65-60.84-13.63-30.15-43.982-67.95-61.282-88.27m233.052-106.58c-63.35 0-127.77 32.12-180.29 58.3-31.194 15.56-58.133 28.98-74.544 30.24a11.225 11.225 0 0 0-9.57 7.04 11.215 11.215 0 0 0 2.143 11.7c.517.57 52.31 57.56 70.049 96.8 11.312 25.04 20.472 41.33 30.532 53.19-4.59 1.27-6.31 2.48-7.16 3.07-2.74 1.94-4.48 5-4.72 8.34-.25 3.35 1.02 6.64 3.44 8.95.29.27 28.26 26.95 45.06 44.15 31.14 31.88 89.11 91.21 214.1 47.78 4.27-1.29 114.76-35.63 129.14-117.08 9.5-53.81-36.14-148.35-38.09-152.35-1.99-3.86-47.4-89.59-165.08-99.51-4.99-.42-10-.62-15.01-.62"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1356.66 638.28h.05-.05m-409.664-.4c18.155-4.92 40.694-16.16 67.694-29.62 54.59-27.21 122.5-61.04 185.39-55.79 105.81 8.93 148.49 86.54 149.9 89.18 1.69 3.51 44.77 93.67 36.45 140.81-12.41 70.15-115.57 102.26-116.61 102.58-113.7 39.53-165.58-13.24-193.44-41.6-.35-.39-.9-.96-1.47-1.53-10.67-10.93-25.78-25.62-35.61-35.09 3.55-.45 7.56-.86 11.46-1.12a7.482 7.482 0 0 0 3.68-13.66c-14.09-9.51-24.6-21.78-42.99-62.42-14.483-32.06-48.306-73.08-64.454-91.74M1186.7 536.96c-62.5 0-126.51 31.91-178.68 57.92-31.546 15.73-58.793 29.31-75.931 30.61a7.462 7.462 0 0 0-6.376 4.71 7.46 7.46 0 0 0 1.425 7.79c.522.57 52.71 58 70.683 97.79 13.809 30.51 23.799 46.42 34.119 57.01-5.17.92-9.62 2.17-12 3.86a7.451 7.451 0 0 0-3.14 5.56c-.17 2.22.68 4.41 2.3 5.95.28.27 28.32 26.99 45.15 44.23.46.47.88.9 1.27 1.33 28.22 28.73 86.53 88.12 208.93 45.53 4.31-1.3 112.69-34.97 126.7-114.19 9.29-52.61-35.84-146.1-37.77-150.05l-.07-.15c-1.84-3.57-46.34-87.53-161.97-97.3-4.87-.41-9.75-.6-14.64-.6"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffac48",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1338.87 791.54c-24.97 48.81-123.07 74.13-162.22 64-33.43-8.64-47.64-25.53-55.31-42.09-7.66-16.56-14.48-37.12-33.18-44.07 0 0 10.97-6.92 20-5.53 0 0-37-31.38-36.08-81.95 0 0 15.26-5.86 27.23-13.17s54.03-23.31 65.55-27.12c0 0-19.21-7.35-21.54-12.36 0 0 19.51-3.97 33.65-13.03 14.15-9.06 91.96-34.32 127.34 34.33 35.39 68.65 46.36 117.91 34.56 140.99"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <g
      clipPath="url(#c)"
      transform="matrix(.01538 0 0 -.01538 -53.725 554.908)"
    >
      <path
        style={{
          fill: "url(#d)",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M9733.2 8073.8s-103.04-121-169.97-299.8c-66.93-178.7-218.73-440.9-554.78-529.9-336.05-89.1-434.04-22.8-434.04-22.8s274.7 79.9 412.32 308.7c137.63 228.8 350.15 564.2 746.47 543.8"
      />
    </g>
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M958.315 798.74c-24.658-5.5-40.308-28.1-53.238-49.59-5.17-8.6-11.997-15.28-18.789-20.36 3.711.67 7.911 1.59 12.642 2.84 29.604 7.85 43.745 30.65 50.391 48.39 2.714 7.26 5.981 13.61 8.994 18.72zm-87.3-86.43c-11.499 0-16.489 2.76-17.764 3.62a7.495 7.495 0 0 0-3.198 7.36 7.483 7.483 0 0 0 5.298 6.02c.234.07 24.888 7.55 36.914 27.54 12.676 21.08 36.123 60.33 81.44 57.99a7.464 7.464 0 0 0 6.48-4.5 7.465 7.465 0 0 0-1.167-7.8c-.2-.24-9.614-11.53-15.693-27.76-7.886-21.04-24.791-48.12-60.567-57.6-13.891-3.68-24.214-4.87-31.743-4.87"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <g
      clipPath="url(#e)"
      transform="matrix(.01538 0 0 -.01538 -53.725 554.908)"
    >
      <path
        style={{
          fill: "url(#f)",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M9074.24 6580s-568.61-125.2-809.64-460.5c-241.03-335.3-270.39-432.6-433.4-453.5 0 0 334.53-115.1 644.33 13.9 309.79 129.1 569.37 308.3 842.09 239.6 0 0-317.39 187.3-603.7 173.2 0 0 112.33 265.6 360.32 487.3"
      />
    </g>
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M805.087 569.86c11.211-1.09 25.781-.71 39.59 5.04 6.821 2.83 13.482 5.97 19.922 9 11.616 5.46 22.91 10.77 34.263 13.98-8.565 2.55-18.033 4.35-27.105 3.92-2.539-.21-5.019 1.08-6.494 3.19a7.45 7.45 0 0 0-.757 7.19c.337.8 6.099 14.21 18.247 30.06-17.065-6.82-38.276-18.03-50.224-34.65-4.859-6.76-8.848-12.55-12.261-17.49-6.099-8.84-10.65-15.44-15.181-20.24zm9.751-15.48c-18.847 0-33.218 4.83-34.15 5.15a7.475 7.475 0 0 0-5.005 7.83 7.475 7.475 0 0 0 6.489 6.66c9.39 1.2 12.237 4.94 25.786 24.57 3.462 5.01 7.505 10.88 12.432 17.73 25.478 35.44 82.993 48.45 85.43 48.98 3.325.75 6.714-.87 8.261-3.89a7.475 7.475 0 0 0-1.67-8.98c-14.096-12.61-23.637-26.84-28.974-36.12 26.362-2.89 50.937-17.22 52.124-17.92a7.473 7.473 0 0 0 3.115-9.28c-1.416-3.45-5.171-5.31-8.74-4.41-18.594 4.68-37.28-4.13-58.975-14.34-6.592-3.09-13.403-6.3-20.532-9.27-12.202-5.08-24.653-6.71-35.591-6.71"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <g
      clipPath="url(#g)"
      transform="matrix(.01538 0 0 -.01538 -53.725 554.908)"
    >
      <path
        style={{
          fill: "url(#h)",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M7351.89 5638.6s-117.88-212.9-407.85-290.7c-208.82-56-440.1-196.5-440.1-196.5s206.39 745.7 847.95 487.2"
      />
    </g>
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M709.55 561.81c-6.684 0-12.939-1.43-18.721-4.29-11.694-5.78-19.863-16.39-25.175-25.79 7.988 3.81 17.553 7.8 26.811 10.29 15.069 4.04 24.893 12.07 30.444 18.03-4.619 1.17-9.077 1.76-13.359 1.76zm-59.155-54.14a7.44 7.44 0 0 0-4.512 1.51 7.477 7.477 0 0 0-2.695 7.95c.439 1.59 11.094 39.02 41.02 53.8 15.923 7.87 34.019 7.83 53.775-.13a7.488 7.488 0 0 0 3.75-10.56c-.547-.98-13.692-24.17-45.391-32.67-19.619-5.27-41.846-18.68-42.065-18.82a7.428 7.428 0 0 0-3.882-1.08"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#b17e4f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11665.5 15741.3c603-2501.9-688.2-3174.4-764.3-5009.1-89.9-2167.59 2258.2-2304.23 3576-1837.74 1157.2 554.48 1822.8 2589.04 1119.1 4752.44l-183.8 486.3c-180.8 453.5-404.8 940.3-612.3 1185.7l-3134.7 422.4"
      transform="matrix(.02306 0 0 -.02306 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#d2623a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14477 8894.32c-1317.7-466.41-3665.9-329.71-3575.9 1837.68 46.7 1125.4 550.4 1813.6 793.9 2754.9 153.4 593.3 203.5 1287.2-29.7 2254.4l3134.7-422.5c207.5-245.4 431.5-732.3 612.3-1185.7l2.5-6.5 181.3-479.9c703.9-2163.4 38.2-4197.9-1119.1-4752.38"
      transform="matrix(.02306 0 0 -.02306 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14477 8894.32c-1317.7-466.41-3665.9-329.71-3575.9 1837.68 46.7 1125.4 550.4 1813.6 793.9 2754.9 990.2-171.9 2712.1-156.7 3719.8 639.7l181.3-479.9c703.9-2163.4 38.2-4197.9-1119.1-4752.38"
      transform="matrix(.02306 0 0 -.02306 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14477 8894.32c-299.6-106.01-652.2-180.8-1017.6-207.76-940.8-69.31-1964.7 179.07-2375 1032.37-131.4 272.6-200.3 607.27-183.3 1013.07 46.7 1125.4 550.4 1813.6 793.9 2754.9 990.2-171.9 2712.1-156.7 3719.8 639.7l181.3-479.9c703.9-2163.4 38.2-4197.9-1119.1-4752.38"
      transform="matrix(.02306 0 0 -.02306 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14477 8894.32c-299.6-106.01-652.2-180.8-1017.6-207.76-940.8-69.31-1964.7 179.07-2375 1032.37-131.4 272.6-200.3 607.27-183.3 1013.07 6.7 160.2 22.5 311.3 45.7 455.5 46.3 290.3 121.7 552.9 209.5 803.8 175.3 501.9 399.5 957.3 538.7 1495.6 990.2-171.9 2712.1-156.7 3719.8 639.7l181.3-479.9c703.9-2163.4 38.2-4197.9-1119.1-4752.38"
      transform="matrix(.02306 0 0 -.02306 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15406.5 13354.5c-883.7-259.7-1680.3 60.4-2073.3 271.9 56.5 353.5 148.2 673.5 255.1 979.1 453.3-452.7 1309-697.1 2114.1-583.3 64.8 9.2 597.6-405.3-295.9-667.7"
      transform="matrix(.01893 0 0 -.01893 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 10519.9c-1139.3-84-2379.3 216.8-2876.2 1250.2 431.7-151.6 1108-237.7 1743.5 313.8 612.9 531.9 989.2-871.4 1132.7-1564"
      transform="matrix(.01904 0 0 -.01904 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16181.3 14733.5c-41.6 0-80.9 25-97.3 65.9-235.2 589.6-451.4 1010.1-625.5 1216-37.3 44.2-31.8 110.3 12.4 147.7 44.1 37.4 110.2 31.8 147.6-12.4 191.1-226.1 413.2-654.7 660.1-1273.7 21.4-53.7-4.7-114.6-58.5-136.1-12.7-5-25.9-7.4-38.8-7.4"
      transform="matrix(.02196 0 0 -.02196 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11881.2 15924c209.6-837.1 240.7-1717.5-25.2-2546.2-250.8-803.7-665.4-1537-722.1-2383-59.6-591.1 72.2-1233.22 547.9-1623.82 770.3-629.83 2002.1-584.82 2904.7-282.7 328 151.49 675.6 516.61 868.8 874.32 528.5 950.5 581 2108 372.8 3160.2-39 214.5-84.6 427.7-122.1 646.4-5 27.8 13.7 54.3 41.5 59.1 22.7 3.9 44.6-8.1 54.3-27.9 148-304.7 231.6-635.9 281.2-969.7 217-1346.8-82.4-3274.94-1422.8-3950.46-985.3-332.84-2279.6-374.01-3125.2 317.95-722.3 601.12-727.7 1612.61-521.1 2454.51 146.4 616.8 450.9 1195.2 634.9 1788.9 189.1 588.7 234.4 1213.6 149.6 1825.4-25.5 205.6-68.2 409.4-113.7 612.3-25.3 126.5 161.1 176.1 196.5 44.7"
      transform="matrix(.02283 0 0 -.02283 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1387.59 789.32c10.1-28.28 21.58-51.29 33-69.8 14.81-24.01 29.5-40.55 41.08-51.4 11.92-11.22 30.04-12.59 43.64-3.44l67.43 45.51-92.21 257.74-37.2-3.52-43.76-4.13c-16.32-1.52-29.48-14.05-31.6-30.3-3.61-27.86-3.58-75.82 19.62-140.66"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff6868",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1381.57 915.24c.29 4.64.72 8.91 1.23 12.81 1.21 9.32 8.67 16.45 18.15 17.35l41.31 3.9c.08-2.66.19-5.37.34-8.13l-.03-1.94-30.08-2.85a38.404 38.404 0 0 1-30.92-21.14"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1485.53 673.58c-4.94 0-9.82 1.83-13.64 5.42-10.28 9.66-22.82 23.72-35.56 43.53 22.4 8.61 35.18 34.35 26.82 58.71 5.56-.23 11.37-.36 17.38-.36.78 0 1.56 0 2.35.01 3.93-8.53 7.99-16.65 12.14-24.35 11.84-22.03 24.35-40.72 36.3-56.26l-34.38-23.17c-3.5-2.37-7.48-3.53-11.41-3.53m-37.48 171.55c-4.83 33.55-5.84 67.18-5.48 94.1l.13.01c1.44-25.25 5.51-54.54 14.21-86.7-3.06-2.49-6.01-4.96-8.86-7.41"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e05353",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="m1442.57 939.23.03 1.94c.03-.64.07-1.29.1-1.93l-.13-.01"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1485.51 643.64c-12.33 0-24.54 4.58-34.09 13.56-22.31 20.96-53.94 60.01-77.93 127.06-23.98 67.05-24.31 117.3-20.35 147.65 3.03 23.26 21.53 41.04 45 43.26l81 7.65c8.15.72 15.51-5.26 16.29-13.48.78-8.23-5.25-15.52-13.48-16.3l-81-7.64c-9.48-.9-16.94-8.03-18.15-17.35-3.53-27.09-3.12-72.27 18.86-133.71 21.98-61.44 50.32-96.63 70.23-115.34 6.86-6.45 17.15-7.22 25.05-1.89l67.46 45.46c6.86 4.61 16.15 2.8 20.76-4.04 4.62-6.85 2.81-16.14-4.04-20.76l-67.47-45.47c-8.62-5.81-18.42-8.66-28.14-8.66"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12140.5 11327.8c395.7-211.7 2262.7-1190.7 2290.2-2336.25l1869.3 957.46c-8.8 55.49-21.2 113.29-37.2 172.39l.1.3c-231.7 847.9-1249.5 2003.1-4049.5 1305.7-20.9-5.1-41.7-10.4-62.9-15.7-39.9-10.2-46.4-64.4-10-83.9"
      transform="matrix(.01642 0 0 -.01642 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff6868",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1601.86 1172.78c-45.84 23.83-105.09 39.93-178.71 39.93-27.83 0-57.69-2.29-89.69-7.34-2.49 1.41-5.02 2.84-7.57 4.26 52.5 11.89 101.04 17.81 145 17.81 61.2 0 113.54-11.48 155.29-34.35-8.35-6.93-16.46-13.7-24.32-20.31"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14298.6 8770.37c-75.1 0-136.9 59.98-138.7 135.45-9.3 387.31-304.6 1186.28-2177.4 2185.78l-18.8 10.1c-67.3 35.9-104.8 108.1-95.7 184 9.2 75.8 62.9 137.1 136.8 156.1 1456.6 373.7 2595 300.7 3383.5-217.1 691.2-453.9 859.4-1091.8 899.6-1344.94 12.1-75.75-39.6-146.87-115.3-158.93-75.9-11.51-146.8 39.64-158.9 115.3-34.4 216.77-179.2 763.27-777.9 1156.47-668.7 439.1-1677.2 520-2926.8 237.1 1730-966.3 2115.7-1789.51 2128.5-2317.19 1.9-76.59-58.8-140.28-135.4-142.14h-3.5"
      transform="matrix(.01657 0 0 -.01657 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13713.8 3764.49c159.7 385.6 924.5 2191.08 268.5 3030.95l1908.8 414.44c26.4-45.14 51.7-94.02 75-145.88l.2-.26c333.7-746.36 283.4-2177.39-2141.3-3327.83-17.9-8.71-36.1-17.16-54.4-25.79-34.7-16.3-71.4 18.91-56.8 54.37"
      transform="matrix(.01765 0 0 -.01765 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14408.6 4064.83c264.4 672.98 398.8 1213.99 446.8 1648.66 336.1-17.32 835.7-37.07 1444.6-43.11-325.5-596.13-969.3-1143.64-1891.4-1605.55"
      transform="matrix(.01705 0 0 -.01705 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13682.9 3557.57c-43.1-.08-85.5 16.39-118.4 47.86-51.6 49.42-67.2 123.81-40 189.61l7.5 18.13c760.2 1831.11 500.1 2582.97 277.4 2868.13-44.2 56.44-34.1 137.94 22.3 182.07 56.5 44.04 138 33.99 182-22.46 303.4-388.5 512.6-1211.2-164-2933.76 1069.9 535.89 1749.9 1191.78 1972.7 1904.98 199.3 638.54-20 1118.86-123.5 1295.73-36.1 61.82-15.4 141.24 46.4 177.39 61.8 36.24 141.1 15.43 177.4-46.38 120.9-206.61 377.3-766.78 147.1-1503.99-262.4-840.91-1041-1567.98-2314.1-2161.09-23.4-10.93-48.2-16.22-72.8-16.22"
      transform="matrix(.01774 0 0 -.01774 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M7095.54 4948.76c-26.01-133.79-69.06-495.36 95.35-954.86 164.51-459.79 427.09-711.79 532-798.9 27.61-22.61 61.63-36.2 97.44-38.62 378.08-24.7 2742.97-153.74 4572.27 338.32 2921.4 785.95 3662.5 2574.35 3849.4 3367.76 58 245.7-33.8 501.92-234.2 654.97-556.7 425.58-1896.6 1159.01-4012.5 565.87-23.5-6.6-47.3-13.34-71-20.28-325-94.78-668.1-220.9-1028.9-383.85-835.1-377.44-1674.73-946.61-2350.47-1462.79-72.45-55.32-142.95-110.11-211.47-164.02-613.19-482.21-1058.62-891.68-1187.11-1011.93-26.21-24.56-43.87-56.39-50.81-91.67"
      transform="matrix(.0317 0 0 -.0317 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16242 6862.41c-186.9-793.41-928.1-2581.76-3849.4-3367.75-1829.3-491.97-4194.19-363.08-4572.27-338.24-35.76 2.43-69.92 15.97-97.54 38.58-104.76 87.16-367.29 339.11-531.95 798.8-164.31 459.65-121.27 821.22-95.3 954.91 6.84 35.43 24.65 67.12 50.81 91.77 128.49 120.15 573.87 529.57 1187.06 1011.83 68.62 53.96 139.03 108.7 211.57 164.12 272 207.84 570.53 424.07 884.6 633.03 466.15 310.23 966.82 604.3 1465.72 829.76 360.9 163 703.9 289.07 1028.7 383.8h.2c23.8 6.89 47.7 13.73 71.1 20.23 2115.8 593.14 3455.9-140.19 4012.5-565.92 200.4-152.95 292.2-409.22 234.2-654.92"
      transform="matrix(.0317 0 0 -.0317 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15763.6 9486.34c-62.9-175.1-204.2-226.68-384-262.61-291-421.94-1351.5-.83-1351.5-.83 151.1-716.49-416.2-1258.39-416.2-1258.39-790.9 105.07-1148.3 528.59-1297.5 980.44 608.8 405.14 1262.6 789.18 1914.2 1083.65 471.2 212.8 919.2 377.5 1343.4 501.2 75.8-263.2 728-803.27 191.6-1043.46"
      transform="matrix(.02427 0 0 -.02427 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#dd3740",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13529.6 7011.46c9.2-18.84 18.4-37.32 27.6-55.35 9.3-18.39 18.5-36.42 28.2-54.09 15.3-29.56 31-58.23 47-86.53 12.6-22.63 25.6-44.8 38.6-66.17 9.5-16.49 19-32.54 28.9-48.13 17.7-29.03 35.3-57.33 53.3-84.37 11.5-17.49 22.7-34.62 33.8-51.39.8-.72.9-1.17 1.3-1.53 12-17.49 23.5-34.25 35.4-50.93 142.2-203 281.4-354.89 390.2-459.55 15-14.6 29.4-28.03 42.8-40.29 33.8-31.37 64-57.33 89.1-78.15 6.6-5.14 13.2-10.28 19.7-15.06 5-3.69 10.2-6.94 15.5-10.27 37.1-22.45 77.7-37.32 120.9-43.72 8.4-1.08 16.7-2.26 25-2.62 19-1.35 40.7-2.61 64.9-4.05 8.3-.36 16.6-1.09 25.2-1.44 60.6-3.88 135.3-7.85 223-12.36 12.2-.89 24.9-1.44 37.9-1.89 4.3-.18 8.8-.72 13.1-.9 16.2-.81 32.8-1.44 49.7-2.53 69.5-3.06 145.8-6.12 228-9.73 37.9-1.53 77-3.06 117.3-4.51 89.2-3.15 184.7-6.49 285.7-9.46 24.6-.63 49.4-1.35 74.3-1.98 100.3-2.98 205.5-5.41 316.1-7.04 82.3-1.62 168-3.06 255.5-3.96 27.2-.18 54.8-.63 82.4-.81 0 0-441.3 449.36-867.5 1212.5-707.8-16.31-1354.2-13.97-1902.9-3.69"
      transform="matrix(.01706 0 0 -.01706 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#dd3740",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13908.2 5009.27c282.2-457.53 504.1-646.21 504.1-646.21 61.5 40.03 121.3 80.79 178.8 122.09 19.3 13.77 38.2 27.53 57 41.3 2.9 2.04 5.6 4.08 8.2 5.93 16.2 11.92 31.9 23.79 47.7 35.71 18.2 13.91 36.4 27.82 54.6 41.78 16.5 12.99 33.1 25.97 49.4 38.96 1.2 1.12 2.6 2.04 3.8 3.16 16.8 13.24 33.1 26.61 49.4 40.04 1.9 1.36 3.7 2.96 5.3 4.28 33.5 27.53 66.3 55.16 98.2 82.98 63.6 55.26 124.3 111.2 182.4 167.18 10.3 9.88 20.7 19.99 30.7 30.11 36 35.46 70.9 71.12 105 106.72 13.1 13.87 26 27.73 38.9 41.59 107.3 115.82 203.4 232.07 289.5 347.36 10.7 14.4 21.2 28.79 31.7 43.14 10.4 14.16 20.7 28.51 30.7 42.86 10.3 14.39 20.2 28.74 30.1 42.85 9.7 14.35 19.4 28.46 29 42.76 9.5 14.15 19 28.45 28 42.51 9.3 14.11 18.3 28.21 27.3 42.27 9.9 15.42 19.7 30.84 29.1 46.21 16.5 26.61 32.5 53.17 47.7 79.48 8 13.62 16 27.43 23.8 41.2 15.8 27.63 30.7 54.82 45.5 81.96 7.4 13.58 14.5 26.9 21.5 40.47 21.2 40.43 41.3 80.21 60 119.47 5.6 11.77 11.1 23.35 16.7 35.12 6.7 14.15 13.2 28.31 19.7 42.46 5.7 12.89 11.4 25.74 17.1 38.33 4.9 11.53 10.1 22.87 15 34.2 5.9 13.72 11.6 27.39 17.2 41.1 5.1 12.16 10.2 24.37 15.1 36.53v.2c23.5 58.03 44.6 114.21 63.3 168.35 4.9 14.3 9.9 28.6 14.5 42.41 3.5 10.61 7 21.21 10.5 31.62.2.44.3 1.07.5 1.75 6.8 20.77 13.2 41.49 19.4 61.58 3.4 11.48 6.8 22.91 10.1 34.15 5.6 18.82 10.8 37.36 15.8 55.5 3.2 11.63 6.2 23.06 9.2 34.49 2.7 9.73 5 19.21 7.4 28.7 1.4 5.59 3 11.43 4.2 17.02 1.8 6.23 3.2 12.7 4.8 19.17 1.6 6.47 3.2 12.5 4.5 18.72 14.3 60.32 19.4 120.98 16.3 180.76-.6 10.6-1.3 21.2-2.5 31.76-1 9.34-2 18.19-3.4 27.29-.4 3.36-1 6.52-1.6 9.68-1 7.83-2.4 15.42-3.9 22.96-1.8 9.29-4 18.53-6.4 27.77-1.1 4.62-2.2 9.25-3.4 13.62-.3 1.07-.6 2.1-.8 2.92-1.9 6.96-3.7 13.67-6 20.33-2.9 9.63-6.1 19.27-9.6 28.85-4 10.85-8.1 21.5-12.5 32.1-4.1 9.34-8.2 18.49-12.8 27.63-378.6-731.67-1068.8-1601.5-2325.8-2295.23"
      transform="matrix(.03162 0 0 -.03162 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13458.6 8191.78c-719 0-1581.3-161.27-2584.6-614.55-1692.34-764.62-3389.02-2310.94-3711.95-2613.15-15.07-14.1-25.11-32.21-29.02-52.36-24.78-127.6-65.5-471.97 92.05-912.31 157.5-440.29 407.4-680.77 507.57-763.7 15.8-13.08 35.02-20.71 55.55-22.07 441.44-28.78 2734.1-147.98 4527.3 334.42 2893.5 778.43 3607.3 2566.25 3779.9 3298.8 51 216.18-30.2 443.04-206.8 577.84-378.3 288.97-1192 767.08-2430 767.08m-4528.53-5156.5c-561.18 0-985.48 23.42-1151.2 34.24-51.14 3.33-99.01 22.31-138.42 54.96-109.3 90.52-381.8 352.29-551.38 826.25-169.57 474.05-124.9 849.23-97.85 988.52 9.76 50.24 34.73 95.25 72.11 130.27 325.87 304.95 2038.1 1865.38 3751.27 2639.37 1204.2 544.03 2338.7 737.37 3372.2 574.61 849.1-133.74 1441.8-478.4 1789.6-743.99 221.6-169.34 323.6-454.25 259.6-725.79-100.4-425.89-340-1068.26-911.5-1710.25-695.7-781.37-1695.4-1351.59-2971.5-1694.89-1139.7-306.6-2476.04-373.3-3422.93-373.3"
      transform="matrix(.03185 0 0 -.03185 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13357.7 7419.04c305.3-854.28 793-1322.65 988-1483.92 51.5-42.54 114.4-67.7 181-71.75 235.4-15.41 885.3-52.37 1773.3-60.84 0 0-835.6 851.03-1317.3 2197.66-481.7 1346.62-375.5 2534.51-375.5 2534.51-681.4-569.24-1160.3-1010.22-1332.4-1171.57-48.7-45.79-81.7-104.92-94.3-170.46-48.4-248.52-128.5-919.9 177.2-1773.63"
      transform="matrix(.01706 0 0 -.01706 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff6868",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1473.46 993.06c.98 10 2.29 18.07 3.43 23.94.79 4.12 2.87 7.86 6 10.81 16.81 15.76 59.64 55.26 121.45 107.74-.01-7.46.1-16 .44-25.48-65.37-55.73-111.1-97.86-129.22-114.9-.73-.68-1.43-1.38-2.1-2.11"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14180.3 10036.8c-546.3-463.85-924.9-812.98-1073.5-952.28-27.6-26.08-46-59.13-53-95.55-45.6-233.96-120.2-863.98 168.3-1669.81l.1-.09c288.3-806.71 745.3-1246.17 928.5-1397.67 29.1-24.04 64.1-38 101.3-40.21 203.8-13.35 718.3-42.96 1434.6-55.42-278.5 337.73-782.9 1031.66-1120 1974.22-337.2 942.56-387.4 1799.12-386.3 2236.81M16300 5554.42l-318.6 3.01c-877.4 8.4-1513.4 44.72-1746.1 59.92-92.8 5.66-180.5 40.4-253.1 100.32-200 165.47-698.6 643.99-1009 1512.4-310.5 867.52-228.7 1554.02-179 1809.01 17.4 91.04 62.9 173.6 131.7 238.12 170.2 159.54 638.4 591.05 1312.3 1153.8l244.3 204.2-27.9-317.3c-1.1-11.4-95.2-1153.74 360.9-2428.99 456.1-1275.17 1253.5-2098.58 1261.6-2106.8l222.9-227.69"
      transform="matrix(.0174 0 0 -.0174 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13320.7 6177.2c475.6-1329.73 1057.6-1824.52 1057.6-1824.52 1309.1 856.36 1729.6 1940.02 1863.8 2509.87 57.9 245.6-33.7 501.87-234.3 655.02-465.5 355.37-1477.6 926.38-3033 757.99 0 0-129.9-768.66 345.9-2098.36"
      transform="matrix(.0317 0 0 -.0317 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e0cccc",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M10139.2 3741.51c-48 0-95.2.11-141.37.38-95.69 114.68-248.77 314.27-401.18 580.02 475.85 12.6 994.05 39.07 1526.05 87.42 65.3 5.94 67.4 186.38 31.2 466.12 228.3-180.78 513.7-281.57 807.9-281.57 122.7 0 247.1 17.59 369.4 54.17 115.1 14.04 229.1 43.01 339.7 86.86 353.3 140.2 615.8 412.56 753.1 737.92 141.3-434.59 336.8-539.88 508.7-539.88 131.4 0 249.1 61.39 318.4 84.31 615.3 203.25 1151.6 440.14 1619.2 698.4 172.4-279.52 325.1-471.06 429.7-588.67-581.8-375.48-1315.1-711.67-2238.7-960.16-1301.9-350.23-2840.9-425.32-3922.1-425.32"
      transform="matrix(.02771 0 0 -.02771 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#c52c33",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16299.9 5126.97c-104.6 117.62-257.3 309.16-429.7 588.67l.1.06c172.4-279.52 325-471.06 429.7-588.73h-.1"
      transform="matrix(.02771 0 0 -.02771 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 6239.15c-789.6 12.02-1363.4 45.04-1583.5 59.38-9.2.65-18.4 1.66-27.6 3.05-172.8 164.72-428.4 454.39-651.5 889.64 228.8-3.51 472.6-5.64 729.1-5.64 288.4 0 593.1 2.68 910.4 8.88 219.9-403.89 446.4-727.51 623.1-955.31"
      transform="matrix(.01663 0 0 -.01663 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 6100.61c-121.7.72-239 2.08-352 3.8-172.9 222.88-394.5 539.51-609.6 934.68 101.3 1.99 203.7 4.34 307.5 7.15 248.5-433.27 498.1-758.67 654.1-945.63m-1928.3 64.88c-56.7 8.6-110.1 32.31-154.8 68.96-101.8 84.61-283.7 252.92-477.9 515.98-53.5 88.58-106.4 184.32-157.2 288.12 50.1-1 100.8-1.81 152.4-2.63 218.3-425.85 468.4-709.26 637.5-870.43M13782 6680.93c-.1.27-.3.45-.4.72.1-.27.3-.45.4-.72m-.4.72c-14.3 22.54-28.6 45.43-42.7 68.96 0-.09 0-.09.1-.18 14.1-23.44 28.3-46.34 42.6-68.78"
      transform="matrix(.017 0 0 -.017 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14371.6 4520.43c-92.3 103.74-226.8 272.63-378.8 519.07 1223.4 675.53 1913 1516.37 2301.4 2236.92l-.3-.54c6.1-67.82 1.9-137.17-14.2-205.78-136.4-579.02-565.3-1683.19-1908.1-2549.67"
      transform="matrix(.03143 0 0 -.03143 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16290.2 10035.4c-.4 119.2 4.6 210.5 9.7 272.3h.1c-5.2-61.8-10.1-153.1-9.6-272.2l-.2-.1"
      transform="matrix(.02521 0 0 -.02521 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#944d4d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16289.1 10300.8c5.4 63.1 10.8 95.5 10.8 95.5.1.1-.1 0 0 0-.1-1.3-5.5-33.6-10.7-95.5h-.1"
      transform="matrix(.02523 0 0 -.02523 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff6868",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 7357.93c-546.7 332.16-1436.8 710.41-2656.4 710.41-189.9 0-387.9-9.14-593.7-29.13-.3 95.5 3.6 168.6 7.8 218.11 4.1 49.6 8.4 75.5 8.6 76.53 193.8 21.01 379.3 30.59 556.3 30.59 1253.8 0 2087.4-480.39 2498.1-793.93 76.1-58.2 136.6-131.06 179.3-212.58"
      transform="matrix(.03148 0 0 -.03148 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12978.8 8170.67c-17.5-194.4-43.7-905.98 347.2-1998.21v-.05c391.5-1094.44 856.8-1613.22 992.6-1748.41 1244.5 833.45 1647.2 1873.07 1776.7 2422.81 51 216.28-30.1 443.14-206.7 577.94-448.9 342.77-1419.8 890.87-2909.8 745.92zM14305 4242.16l-41.2 34.97c-24.2 20.62-599.4 520.27-1073.8 1846.7-474.5 1325.89-352.9 2092.82-347.5 2124.8l9.2 53.9 54.3 5.89c1572.2 170.25 2598.5-408.65 3070.3-768.87 221.8-169.28 323.7-454.24 259.6-725.92-136-577.56-561.9-1675.82-1885.7-2541.91l-45.2-29.56"
      transform="matrix(.03185 0 0 -.03185 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12895.9 7302.95s945.9-241.47 1885.9 94.76c940 336.32 1518.2 1123.05 1518.2 1123.05s-946 241.47-1886-94.85c-940-336.23-1518.1-1122.96-1518.1-1122.96"
      transform="matrix(.01664 0 0 -.01664 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#944d4d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1394.95 789.96v.01-.01m0 .01"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff6868",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1404 801.15c7.48 8.76 19.46 21.86 35.42 36.36-15.96-14.5-27.94-27.6-35.42-36.36"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff6868",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M12979.7 7406.49c69.2 80.98 179.9 202.09 327.5 336.14 261.8 237.68 639.6 516.14 1107 683.28 385.9 138.03 772.8 178.8 1094.9 178.8 461.3 0 789.5-83.58 790.9-83.86-.5.1-40.1 10.08-111.1 23.21-53-40.21-107.5-80.43-163.7-120.37-459.1-7.94-995.8-73.95-1531.5-265.6-520.8-186.1-967.4-465.48-1322.8-742.63-64.7-3.98-128.5-6.94-191.2-8.97m3242.2 1017.29h-.1c50.8 59.82 77.6 96.15 78.2 96.98-.6-.83-27.4-37.25-78.1-96.98m78.1 96.98"
      transform="matrix(.01664 0 0 -.01664 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1394.95 789.97"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="m1400.66 797.17-.01.01c.99 1.2 2.1 2.52 3.33 3.97h.02a291.47 291.47 0 0 1-3.34-3.98"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1394.95 789.97c.08.1 2.02 2.74 5.7 7.21l.01-.01c-3.69-4.47-5.63-7.1-5.71-7.2"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13687 7219.18c-460.4 0-788.3 83.21-790.9 83.86.9-.19 39.7-9.8 108.2-22.56 53.1 44.65 108.6 89.77 166.6 134.98 473 29.39 997.6 114.91 1521.4 302.39 507.9 181.76 955.9 437.83 1332.9 705.84 33.1.65 66 .93 98.3.93 33.2 0 66-.28 98.3-.84-187.5-220.85-702.2-761.95-1440-1025.8-385.9-138.11-772.8-178.8-1094.8-178.8m2613 1301.58v.09-.09"
      transform="matrix(.01664 0 0 -.01664 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e05353",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1400.66 797.17a291.47 291.47 0 0 1 0 0"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#8b3e3e",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 0.1,
      }}
      d="M1394.95 789.96v.01c.08.1 2.02 2.73 5.71 7.2-3.66-4.43-5.6-7.06-5.71-7.21"
      transform="matrix(.15382 0 0 -.15382 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e05353",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13004.3 7280.48c-68.5 12.76-107.3 22.37-108.2 22.56 1 1.38 18.9 25.7 52.7 66.65 9.2 11.1 19.5 23.39 30.9 36.8 62.7 2.03 126.5 4.99 191.2 8.97-58-45.21-113.5-90.33-166.6-134.98m3020.9 1143.21c56.2 39.94 110.7 80.16 163.7 120.37 71-13.13 110.6-23.11 111.1-23.21v-.09c-.6-.83-27.4-37.16-78.2-96.98-32.3.56-65.1.84-98.3.84-32.3 0-65.2-.28-98.3-.93"
      transform="matrix(.01664 0 0 -.01664 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13038.5 7330.29c323.1-46.27 950.2-89.52 1566.3 131.03 616 220.28 1073.2 651.21 1293.7 892-323.1 46.37-950 89.43-1566-131.03-617.9-221.1-1074.3-651.48-1294-892zm-256.8-91.99h.4zm782.9-221.01c-462.2 0-793.6 82.28-816.8 88.23-44.1 11.18-79.8 43.71-95.2 86.59-15.3 42.89-8.3 90.53 18.6 127.28 24.5 33.26 608.9 817.41 1569 1160.93 960.3 343.43 1909.4 107.94 1949.3 97.77 44.2-11.27 79.8-43.71 95.2-86.59 15.3-42.88 8.3-90.62-18.7-127.27-24.4-33.26-608.8-817.51-1568.9-1161.03-401.2-143.49-800.6-185.91-1132.5-185.91"
      transform="matrix(.01679 0 0 -.01679 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13143.3 6624.04c236.9-598.01 802.4-967.94 1406.9-985.38 205.7-5.99 416.2 28.96 619.6 109.71 739.7 293.44 1130.2 1088.69 936.3 1839.08-15.9 63.04-36.4 125.61-60.9 187.32-317.6 801.51-1225.1 1193.75-2026.5 875.74-801.4-317.94-1193.3-1225.03-875.4-2026.47"
      transform="matrix(.0231 0 0 -.0231 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e53939",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14550.2 5638.66c205.7-5.99 416.2 28.96 619.6 109.71 739.7 293.44 1130.2 1088.69 936.3 1839.08-3.8-11.32-92-254.44-403.4-113.84-319 143.86-1086.5-210.43-1086.5-210.43l-66-1624.52"
      transform="matrix(.0231 0 0 -.0231 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14461.9 8608.13c-180.4 0-363.7-33.38-541.3-103.78-365.7-145.08-653.1-423.89-809.2-785.1-156-361.14-162.1-761.48-17-1127.25 145-365.76 423.8-653.15 785-809.18 361.2-156.09 761.6-162.16 1127.3-17.08 755.1 299.46 1125.7 1157.33 826.3 1912.34-229 577.41-784.7 930.05-1371.1 930.05m1.4-3095.01c-218.2 0-436.2 44.66-642.6 133.86-397.5 171.74-704.2 487.95-863.8 890.46-329.6 830.89 78.3 1774.92 909.2 2104.47 831 329.41 1774.9-78.44 2104.4-909.27 329.5-830.88-78.5-1774.92-909.2-2104.46-193.5-76.73-395.9-115.06-598-115.06"
      transform="matrix(.02331 0 0 -.02331 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15148.6 5787.35c-773.2-306.66-1648.6 71.58-1955.3 844.77-306.6 773.2 71.6 1648.59 844.8 1955.17 773.2 306.65 1648.6-71.52 1955.2-844.71 306.7-773.26-71.5-1648.58-844.7-1955.23"
      transform="matrix(.02271 0 0 -.02271 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14742 5815.1c-575.1 0-1120 345.86-1344.6 912.04-5.3 13.55-10.5 27.23-15.4 40.84 285.6-412.55 756.8-654.22 1250-654.22 186.6 0 376.4 34.61 560.5 107.67 574.4 227.51 932.9 765.86 959.3 1345.59 148.2-678.51-212.8-1385.95-879-1650.13-174.2-69.09-353.9-101.79-530.8-101.79"
      transform="matrix(.02249 0 0 -.02249 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ffbebe",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13806.7 7807.91c94.5 484.26 425.8 912.24 917.5 1107.26 180.4 71.6 366.7 105.48 550.1 105.48 380.8 0 748.9-146.32 1025.7-405.14-274.2 192.75-604.1 299.02-943.7 299.02-201.1 0-405.7-37.29-604.1-115.98-462.5-183.4-795.3-554.02-945.5-990.64"
      transform="matrix(.0217 0 0 -.0217 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15069.5 5757.13h.3-.3m-554.1 2815.38c-174.2 0-351.2-32.2-522.7-100.25-729.2-289.22-1087.2-1117.73-798-1846.93 289.2-729.15 1117.7-1087.15 1846.9-798 729.2 289.22 1087.2 1117.73 798 1846.94-221.2 557.69-757.8 898.24-1324.2 898.24m3.7-2996.53c-626.6 0-1220.2 376.66-1464.9 993.63-154.9 390.74-148.5 818.54 18.3 1204.37 166.7 385.89 473.7 683.8 864.4 838.75 390.8 154.95 818.5 148.55 1204.4-18.26 385.9-166.67 683.8-473.67 838.8-864.49 319.9-806.68-76.1-1723.17-882.7-2043.11-189.7-75.25-385.7-110.89-578.3-110.89"
      transform="matrix(.02283 0 0 -.02283 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#32caf9",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15494.7 6480.9c-540.8-214.43-1153.1 50.08-1367.6 590.89-214.4 540.73 50.1 1153.06 590.9 1367.49 540.7 214.5 1153-50.01 1367.5-590.82 214.5-540.81-50.1-1153.06-590.8-1367.56"
      transform="matrix(.02196 0 0 -.02196 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#70dafb",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15658.6 6635.39c-245.1 2.9-500.1 67.07-702 200.73 83-20.61 168.9-31.31 255.8-31.31 130.7 0 263.4 24.16 392.3 75.3 344.4 136.42 577.9 432.49 649.5 769.43 45.8-422.43-187.7-841.54-595.6-1014.15"
      transform="matrix(.02172 0 0 -.02172 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15023.4 8389.33c-118.7 0-239.3-21.95-356.2-68.28-497-197.11-740.9-761.74-543.9-1258.72 197.2-496.92 761.8-740.91 1258.8-543.81 496.9 197.04 740.9 761.75 543.8 1258.66-150.8 380.07-516.5 612.15-902.5 612.15m2.6-2095.43c-448.3 0-872.9 269.49-1047.9 710.81-228.9 577.1 54.4 1232.73 631.5 1461.61 577 228.88 1232.7-54.48 1461.5-631.52 228.9-577.11-54.4-1232.74-631.4-1461.55-135.8-53.85-275.9-79.35-413.7-79.35"
      transform="matrix(.02208 0 0 -.02208 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#ff2727",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14691.7 7354.5c222.8-562.56 859.8-837.72 1422.2-614.5 66.3 26.3 128.4 58.06 186.1 95.14-481.6-58.57-1071.4 123.27-1257.7 612.03-221.3 580.33 102 1131.6 537.9 1398.67-92.1-10.93-183.8-33.8-274-69.5-562.5-222.85-837.7-859.79-614.5-1421.84"
      transform="matrix(.02111 0 0 -.02111 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e01f1f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15710.1 6662.48c-435.9 0-848.1 262.05-1018.4 692.02-52.6 132.3-77.5 268.67-77.6 402.87-.2 436.01 261.9 848.57 692.1 1018.97 90.2 35.7 181.9 58.57 274 69.5-338.9-207.63-609.7-586.96-609.4-1018.17 0-123.49 22.2-251.27 71.5-380.5 164.1-430.19 640.5-622.59 1080.8-622.59 59.9 0 119.2 3.57 176.9 10.56-57.7-37.08-119.8-68.84-186.1-95.14-132.6-52.6-269.3-77.59-403.8-77.52"
      transform="matrix(.02111 0 0 -.02111 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15129.7 8594.49c-191.9-107.3-342.6-273.72-431.4-479.05-106.9-247.21-111-521.15-11.7-771.33 99.3-250.39 290.1-447.17 537.3-553.97 184.2-79.55 383.4-102.15 576.3-67.45-387.7 56.73-765.7 259.6-916.3 654.75-178.9 469.35-25.5 909.49 245.8 1217.05zm493.7-2050.17c-157.5 0-314.9 32.31-463.9 96.65-287.2 124.03-508.7 352.48-623.9 643.3-115.4 290.46-110.5 608.6 13.5 895.65 124.1 287.13 352.6 508.69 643.3 623.89 94.6 37.46 193.2 62.45 292.9 74.26 37.7 4.57 73.8-18.04 86.3-54.12 12.6-36.01-1.8-75.93-34.3-95.93-388.3-237.78-710.8-743.79-501.5-1292.69 167.1-438.33 712.5-611.93 1165.1-556.94 37.6 4.28 73.4-17.53 86.2-53.1 12.9-35.65-.7-75.43-32.5-95.93-61.5-39.49-128.4-73.76-199.1-101.8-139.8-55.49-286-83.24-432.1-83.24"
      transform="matrix(.02123 0 0 -.02123 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m15936.1 11079.9-1855.9-369.8c-219.6-43.8-435.3 100.1-479.2 320l-196.7 987.6c-38.4 192.5 67.3 381.7 241.8 455.2 24.6 10.6 50.8 18.5 78.2 24l1855.5 369.7c220 44 435.7-100 479.3-320l156-782.2 41-205.3c43.9-219.9-100.3-435.4-320-479.2"
      transform="matrix(.0229 0 0 -.0229 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m15936.1 11079.9-1855.9-369.8c-219.6-43.8-435.3 100.1-479.2 320l-196.7 987.6c-38.4 192.5 67.3 381.7 241.8 455.2 24.6 10.6 50.8 18.5 78.2 24l1855.5 369.7c220 44 435.7-100 479.3-320l156-782.2 41-205.3c43.9-219.9-100.3-435.4-320-479.2"
      transform="matrix(.0229 0 0 -.0229 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m15936.1 11079.9-1855.9-369.8c-219.6-43.8-435.3 100.1-479.2 320l-196.7 987.6c-38.4 192.5 67.3 381.7 241.8 455.2 24.6 10.6 50.8 18.5 78.2 24l1855.5 369.7c220 44 435.7-100 479.3-320l156-782.2 41-205.3c43.9-219.9-100.3-435.4-320-479.2"
      transform="matrix(.0229 0 0 -.0229 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e8e8e8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m15936.2 11078.4-1855.5-369.7c-219.6-43.8-435.3 100.1-479.2 320l-196.7 987.4c-38.3 192.5 67.3 381.6 241.7 455.1 119.3-49 195.2-80.4 195.2-80.4 172-1217.2 1352.5-1304.7 1296.5-511.5-15.1 213 750.1 275.7 782.5 134 79.5-347 217.4-311.7 294.4-250.4l41.1-205.4c43.8-219.9-100.4-435.2-320-479.1"
      transform="matrix(.0229 0 0 -.0229 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15528.5 12705.7c-25.1 0-50.5-2.4-76-7.5l-1685.1-335.8c-210.3-41.9-347.2-247-305.4-457.2l163.7-820.8c20.1-101.3 79-189 165.7-246.8 86.6-57.9 190.1-78.6 291.4-58.5l1685.1 335.8c101.3 20.2 189 79.1 246.9 165.7 57.8 86.7 78.6 190.2 58.4 291.5l-163.6 820.9c-36.8 184.6-199.5 312.7-381.1 312.7m-1522-2134.2c-115.1 0-227.9 34.1-326.2 99.8-131.1 87.5-220.2 220.3-250.8 374l-163.5 820.8c-63.6 318.4 143.9 629 462.2 692.4l1685.2 335.9c318.7 63.4 628.9-144 692.3-462.4l163.7-820.8c30.6-153.7-.8-310.5-88.3-441.6-87.5-131.1-220.3-220.2-374.1-250.8l-1685-335.8c-38.5-7.6-77.1-11.5-115.5-11.5"
      transform="matrix(.023 0 0 -.023 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m16300 11688.5-2.4-8.2-1275.9-254.2c-171.8-34-341.1 39.1-437.7 173.5-35.3 48.5-60.9 105.2-73.2 168l-93.5 468.1-30.8 154.5-53.9 271.2-31.8 159.8c-46.7 234.7 106.9 464.5 341.2 511.4l427.9 85c-189.8-76.4-305.2-280.2-264.1-487.5l62.2-312.8 51.3-256.4 96.4-485.1c46.9-234 277-387.7 511.3-341.2l773 153.9"
      transform="matrix(.02147 0 0 -.02147 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m16300 11585.3-2.5-8.2-1459.8-290.9c-169.7-33.6-336.9 38.6-432.3 171.3-34.9 48-60.1 104-72.3 166l-92.3 462.3-30.5 152.6-53.2 267.9c82.5 28.6 197.2 39.3 283.2-73.7 119.4-156.6 197.7-81 245.6 30.1l50.6-253.2 95.3-479.1c46.3-231.2 273.6-383 505.1-337.1l963.1 192"
      transform="matrix(.02174 0 0 -.02174 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#5e3519",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m14184 12742.7-75.2-14.9c-170.4-34.2-281.5-200.5-247.4-370.8l202-1014c16.4-82.1 64.1-153.2 134.4-200.2 70.3-46.9 154-63.6 236.1-47.4l86.5 17.3c-89.4 75.8-150.1 178.5-173.2 294.5l-202 1013.9c-22.3 111.9-6.7 223.9 38.8 321.6zm188-1859.6c-101.8 0-201.7 30.1-288.7 88.3-116.2 77.6-195 195.2-222.1 331.4l-202 1013.8c-56.3 281.8 127.4 557 409.4 613.4l411.5 81.9 58.7-196.7c-140.4-56.8-220.9-205.5-191.4-353.8l202.1-1013.9c16.3-82.2 64-153.3 134.3-200.2 70.2-47 154.1-63.7 236.2-47.4l1186.5 229.2c7 1.3 14.5 2.8 22.5 4.1l29.7 5.9.4-1.9c51.6 4.4 107.1-5.5 121.3-79.5l19.6-101.2-101.5-19.7-1724.3-343.5c-34-6.8-68.2-10.2-102.2-10.2"
      transform="matrix(.02231 0 0 -.02231 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#22a7f0",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16286.8 12438.3c7.3-37.9 13.2-353.9 13.2-353.9s-96.9-18.7-215.8-41.7c-148.5-28.7-298.7 101.8-335.4 291.6-36.7 189.7 54 366.9 202.6 395.6 100.2 19.4 215.7 41.7 215.7 41.7s107.8-271.6 119.7-333.3"
      transform="matrix(.02211 0 0 -.02211 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16286.8 12438.3c7.3-37.9 13.2-353.9 13.2-353.9s-96.9-18.7-215.8-41.7c-148.5-28.7-298.7 101.8-335.4 291.6-36.7 189.7 54 366.9 202.6 395.6 100.2 19.4 215.7 41.7 215.7 41.7s107.8-271.6 119.7-333.3"
      transform="matrix(.02211 0 0 -.02211 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m16041.5 12620.8-151.7-28c-47.6-9.2-88.6-43.2-115.6-95.9-30.2-59-38.9-132.4-24.5-206.9 27.9-144 139.3-248.7 242.9-228.7l149.1 28.9c-3.9 227.7-8.2 268.1-9.1 274.4-4.2 21-30.3 99.2-91.1 256.2m-74.7-717.2c-170.1 0-329.3 148.8-369.6 356.9-20.9 108.4-7.2 217.4 38.8 307.1 49.2 96.1 128.9 159.2 224.4 177.7l149.9 28.9c74.6 14.5 148.6-26.5 176.1-97.4 88-227.4 96.1-269.4 98.7-283.3 2.2-11 7.1-36.7 13.1-298.3 1.8-76.7-51.6-142.9-127-157.4l-149.1-28.9c-18.5-3.5-37-5.3-55.3-5.3"
      transform="matrix(.02222 0 0 -.02222 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdd907",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16263.8 12299.4c36.2-187-53.2-361.5-199.6-389.8-146.4-28.3-294.4 100.4-330.6 287.4-36.2 187 53.2 361.5 199.7 389.8 146.4 28.3 294.4-100.3 330.5-287.4"
      transform="matrix(.02244 0 0 -.02244 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16267.8 12404.1c-31 75.5-81.2 135.8-140.3 171.8 59.1-36 109.3-96.3 140.3-171.8"
      transform="matrix(.0224 0 0 -.0224 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#d0b2b2",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16154.3 12779c36.2 51 86.3 87.1 145.7 99.5-59.4-12.4-109.4-48.5-145.7-99.5"
      transform="matrix(.02193 0 0 -.02193 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fef4b4",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15770.9 12177.1c-3.9 13.2-7.2 26.8-10 41-21.5 111.2 1.3 218.1 54.2 292.5 35.5 50 84.4 85.3 142.6 97.4 1.1.2 2.1.5 3.2.7 13.6 2.6 27.3 3.9 40.8 3.9 43.6 0 86.4-13.2 125.4-37 59-36 109.3-96.3 140.3-171.8 10.6-25.9 19-53.8 24.6-82.9.8-3.8 1.5-7.6 2.1-11.4 2.9-17.3 4.7-34.5 5.5-51.5.3-6.2.4-12.5.4-18.6-43.6 148.1-158.8 250.7-280.3 250.7-13.5 0-27.2-1.3-40.9-3.9-125.1-24.3-208.4-155-207.9-309.1"
      transform="matrix(.0224 0 0 -.0224 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15733.7 12196.9c36.1-186.8 184.1-315.6 330.6-287.3 146.4 28.4 235.7 202.9 199.6 390-5.6 29.4-14.1 57.6-25 83.7-14.3-133.6-93.1-241.3-205.4-263.1-123.2-23.9-247.8 63.4-305.6 203.4-4.2-40.3-2.6-83.1 5.8-126.7"
      transform="matrix(.02244 0 0 -.02244 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15914.8 12467.9c-8.5 0-17-.8-25.5-2.5-47-9.1-87.5-42.7-114-94.6-29.8-58.2-38.4-130.7-24.3-204.1 27.5-142.1 137.2-245.4 239.7-225.6 46.9 9.1 87.4 42.7 114 94.6 29.8 58.2 38.4 130.7 24.2 204.1-25.2 130.3-119.6 228.1-214.1 228.1zm50.5-682.5c-167.9 0-325 146.9-364.7 352.2-20.6 106.9-7.1 214.5 38.3 303 48.5 94.8 127.1 157 221.3 175.2 187 36.3 375.1-119.4 419.1-347 20.7-106.8 7.1-214.4-38.2-302.9-48.6-94.8-127.1-157-221.3-175.2-18.2-3.6-36.5-5.3-54.5-5.3"
      transform="matrix(.02252 0 0 -.02252 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16055.9 11780.9c-39.3 0-74.2 28.3-81.2 68.4l-96.1 549.9c-27.1 155.1 25 312.4 139.4 420.6 75.8 71.8 109.2 175.8 89.1 278.3l-151.1 772.3c-8.8 44.8 20.3 88.1 65.1 96.8 44.9 9 88.1-20.4 96.8-65.1l151.1-772.3c31-158.2-20.4-318.9-137.6-429.9-74-70.1-107.7-171.8-90.2-272.3l96.1-549.9c7.8-44.9-22.2-87.7-67.1-95.5-4.8-.9-9.5-1.3-14.3-1.3"
      transform="matrix(.0209 0 0 -.0209 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15774.3 13825.5s389.2 0 449.1-289.2c76.6-370.1-269.4-469.9-269.4-469.9s-2359.2-275.2-2950.7 524.1c0 0-294.2 417.2 236.1 728.1 0 0 766.1-646.5 2534.9-493.1"
      transform="matrix(.01953 0 0 -.01953 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15953.9 13066.4s-2359-275-2950.3 524.2c0 0-173.7 246.2-13.9 501.1 48.1 77.1 127 154.7 249.8 226.9 0 0 539.8-455.3 1759.2-511 233.6-10.5 491.5-6.6 775.6 18.1 0 0 389.4 0 449.3-289.2 76.4-370.2-269.7-470.1-269.7-470.1"
      transform="matrix(.01953 0 0 -.01953 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15953.9 13066.4s-2359-275-2950.3 524.2c0 0-173.7 246.2-13.9 501.1 0 0 190.5-500.8 1106.5-683.1 779.4-155.3 940.2 104.7 902.5 399 233.6-10.5 491.5-6.6 775.6 18.1 0 0 389.4 0 449.3-289.2 76.4-370.2-269.7-470.1-269.7-470.1"
      transform="matrix(.01953 0 0 -.01953 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13140.8 14075c-103.7-73.5-166.3-156-186.5-246-32-142.5 51.8-267.3 52.6-268.5 443.6-599.5 2166.8-545.3 2808.8-472.2 52 17.9 225.7 96.3 177.8 328-36.6 176.8-293.2 192.5-327.6 193.8-1502.1-129-2294.7 308.7-2525.1 464.9zm1814.5-1264.6c-752.4-.1-1760.5 103.1-2138.5 613.9-7.3 10.4-144.3 208.2-91.6 452 37.8 174.6 159.3 323.1 360.9 441.3 42.8 25.1 96.7 20.4 134.5-11.5 7.1-5.8 753.6-608.1 2431.4-462.5 3.3.4 6.7.5 10.1.5 178.9 0 498.2-80.1 560.4-380.4 77.5-374.6-194.8-558-349.6-602.6-6.2-1.8-12.4-3.1-18.9-3.8-38.4-4.5-416.1-46.9-898.7-46.9"
      transform="matrix(.01967 0 0 -.01967 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13833.6 11185.5c-37.6-499.1-16.9-1164.6 129.5-1732.39-27.6-113.18-35-396.59 59.4-619.83-48.6-186.76-36-367.42 52.1-495.15 179.8-260.79 657.2-216.31 800.3-198.72 242.8 29.71 306.1 127.2 357.8 112.27 51.6-14.93 306.8-150.88 523.4-1.68 181.8 125.22 198.6 429.5 162.7 741.7 241.4-37.09 345.5 84.24 345.5 229.95 0 277.85-491.4 512.44-502.2 576.49 537.9 604.46 537 1556.66 162.6 2167.56-277.5 452.7-879.7 902.5-1824.4 454.1"
      transform="matrix(.0202 0 0 -.0202 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 9241.78c0-145.87-104.5-267.7-346.5-230.29 36.3-313.04 19.2-617.83-162.9-743.4-217.1-149.54-472.8-13.44-524.5 1.75-51.9 14.81-115.3-82.82-358.7-112.74-143.3-17.4-621.8-61.91-801.9 199.15-88.4 128.16-101 309.37-52.2 496.31-94.7 223.96-87.3 507.92-59.7 621.2-146.6 569.04-167.5 1236.24-129.5 1736.14l2 9.2c14.5 1.1 70.5-14.9 138.7-303.7 70.2-299.3 32.3-794.1 367.5-929.99 417.8-169.46 1442.5-123.58 1771 464.89-68.5-229.7-182.6-446.3-346.7-630.68 2.8-16.8 37.9-44.81 88.8-82.14-116.7-108.46-472.3-367.84-1156.1-307.39-602.1 53.36-450.6-242.27-425.5-409.52 25.5-167.17-99.6-541.88 168.4-635.77 267.9-93.59 804.5 63.66 934.9 379.52 0 0-23.7-290.21 176.3-307.31 233.7-20 322.2 602.11 196.6 922.02 0 0 180.7-226.33 355-183.2 83.9 20.84 126.1 88.47 146.7 140.99 11.7-30.84 18.3-62.83 18.3-95.04"
      transform="matrix(.02015 0 0 -.02015 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13714.3 11039.4c3.7-424.3 26.9-849.6 95.5-1268.02 21.8-138.59 50.5-275.6 85.5-409.99 4.5-17.37 4.6-34.81 1.1-51.21-8.8-38.28-12.9-86.55-13.9-134.76-2.8-136.71 18.2-286.13 70.1-408.48 10.3-24.37 11.8-50.16 5.8-74-52.2-198.45-25.2-427.96 185.6-499.17 96.2-37 213.2-49.18 330.1-48.21 138.6 3.99 286.1 11.36 409.3 76.63 60.8 34.37 134.7 70.92 209.7 38.35 16-7.14 49.6-18.27 65.7-24.89 465.8-164.46 472.3 296.29 431.8 627.55-8 69.78 42.1 132.8 111.9 140.84 47.5 1.43 106.3-12.26 145.5-.98 59.9 15.27 78.3 37.08 82.8 98.29-8 113.48-110.4 194.09-206 276.66-71.7 59.03-151.3 109.04-224 171.91-57.1 44.74-93.7 142.28-34.6 205.9 795.5 862.18 343.1 2742.68-1013.3 2571.98-172.8-17.5-341.1-64.7-508.8-116.7-62-19.2-101.3 67.4-46.7 102 507 331.2 1163.2 331.6 1627.1-84 729.5-657.4 775.5-1905.3 132.1-2639.1l30.1 104.98c-6.2 29.18-11.2 33.24-19 44.67 65.9-56.25 145.4-106.11 215.8-164.39 135.3-109.94 305.6-270.57 301.3-479.25-2.1-266.51-251.3-390.28-487.2-343.96l145.7 140.17c20.8-203.79 33.2-424.95-44.8-627.99-109.2-296.67-450.2-374.5-723.4-263.8-14.8 6.39-54.2 19.93-68.1 26.02-2.1.82-4.7 1.58-.3.3 8.5-2.48 18.1-3.01 24.5-2.86 14 .83 18 3.38 17.2 2.86-49.4-23.77-101.7-53.47-155.5-70.54-376.3-109.49-1025.8-107.01-1164.9 351.41-46.7 153.78-32.4 305.61 3.3 443.15l5.4-75.12c-85.1 224.39-105 449.69-60.1 684.31l.4-54.82c-72.4 287.42-108.6 579.72-120.1 871.46-10.7 291.2 1.1 583.2 45.3 871.3 11.7 66.9 110.9 60.5 112.1-8.5"
      transform="matrix(.02045 0 0 -.02045 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8596.81 15794.3c-281.17 0-566.45-35.6-850.94-110-1793.7-469.5-2871.07-2310.9-2401.57-4104.7 469.51-1793.72 2310.72-2871.09 4104.65-2401.54 1793.75 469.5 2871.15 2310.84 2401.65 4104.64-395.2 1509.3-1761.8 2511.6-3253.79 2511.6m1.24-6858.3c-1550.85 0-2970.85 1041.39-3381.47 2610.2-487.96 1864.2 631.71 3777.9 2495.87 4265.8 1864.13 488 3777.85-631.7 4265.85-2495.9 487.9-1864.2-631.7-3777.84-2495.94-4265.81-295.38-77.3-592.32-114.29-884.31-114.29"
      transform="matrix(.03484 0 0 -.03484 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14746.2 9832.82c318.8-118.85 813.9-46.08 1087.7 152.28 99.3 70.1 176.3 162.7 229.4 266.9 28.7 57.2 98.6 80.2 155.7 51.1 59.4-30 81-105.2 46.7-162.2-232.2-399.07-723.7-536.15-1156.3-507.74-146.8 12.96-293.1 26.55-425.3 102.41-63.1 42.15-2.8 136.54 62.1 97.25"
      transform="matrix(.01993 0 0 -.01993 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13896.9 9989.78c-2.4 0-4.8.08-7.2.31-47.2 3.89-82.2 45.31-78.3 92.41 81.2 980.3 660.2 1765.4 1407.9 1909.2 529.6 101.9 908.2-107.1 1066-588.4 14.7-45-9.7-93.4-54.7-108.1-44.9-14.8-93.4 9.7-108.1 54.7-163.4 498.5-563.8 532.6-870.8 473.6-672.5-129.4-1194.6-851.1-1269.5-1755.1-3.7-44.8-41.2-78.62-85.3-78.62"
      transform="matrix(.02014 0 0 -.02014 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#8ce4ff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M6541.06 13152c-282.86 360.7-318.03 1625.6 251.68 2407.3 374.86 514.3 879.01 740.7 1227.48 740.7 182.46 0 322.18-62 378.38-177.2 23.47-48.1 21.46-106.1.15-172.7-513.81-230.4-983.46-573.7-1272.08-932.9-439.35-546.8-645.62-1212.6-585.61-1865.2"
      transform="matrix(.03153 0 0 -.03153 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13208.7 13141c488.2-1081.9 1617.1-1365.1 2226.4-1974.6 370.8-88.9 755.4-172.8 762.1-386.6 4.5-148.7-262.5-148.7-262.5-148.7s365.3-341.7 347.6-585.2c-11.7-160.49-125.2-194.2-285.3-174 27.9-178.89-151.3-284.06-349.1-230.34-1.3-67.68-57.4-192.34-212.8-221.44-296.5-55.6-521.5 276.66-809.3 398.78-1105.8 53-2413.5 1423.8-2664.3 1990.7l1247.2 1331.4"
      transform="matrix(.02368 0 0 -.02368 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13208.7 13141c488.2-1081.9 1617.1-1365.1 2226.4-1974.6 370.8-88.9 755.4-172.8 762.1-386.6 4.5-148.7-262.5-148.7-262.5-148.7s365.3-341.7 347.6-585.2c-11.7-160.49-125.2-194.2-285.3-174 27.9-178.89-151.3-284.06-349.1-230.34-1.3-67.68-57.4-192.34-212.8-221.44-296.5-55.6-521.5 276.66-809.3 398.78-1105.8 53-2413.5 1423.8-2664.3 1990.7l1247.2 1331.4"
      transform="matrix(.02368 0 0 -.02368 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16296.6 10054.7c-11.6-160.64-125.1-194.45-285.6-174.03 28-179.12-151.4-284.38-349.3-230.68-1.4-67.8-57.3-192.5-212.9-221.63-297-55.65-522.1 277.09-810.1 399.06-1106.8 53.25-2415.7 1425.28-2666.8 1992.58l1248.3 1332.5c488.8-1082.8 1618.6-1366.2 2228.6-1976.3 371.2-89 756-172.9 762.5-387 4.6-148.8-262.5-148.8-262.5-148.8s235.5-220.4 319.4-433.3c20.4-52.5 31.8-104.3 28.4-152.4"
      transform="matrix(.02366 0 0 -.02366 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#eaeaea",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16296.6 10054.7c-11.6-160.64-125.1-194.45-285.6-174.03 28-179.12-151.4-284.38-349.3-230.68-1.4-67.8-57.3-192.5-212.9-221.63-297-55.65-522.1 277.09-810.1 399.06-1106.8 53.25-2415.7 1425.28-2666.8 1992.58 214.4-17.7 661.6-1017.4 1927.5-1519.1 517-204.9 739.7-148.7 949.9-299.3 126.9-90.96 332.9-387.82 512.3-400.69 191.2-13.85 183.2 288.02 183.2 288.02s153.7-91.61 269.1-46.17c97.2 38.17 93.3 270.94 93.3 270.94s231.2-68.7 361 93.4c20.4-52.5 31.8-104.3 28.4-152.4"
      transform="matrix(.02366 0 0 -.02366 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13088.3 12997.6c232.6-432.4 588-764.8 991.7-1036.9 411.6-283.4 865.4-507.1 1231.6-864.8l-45.7 25.6c98.5-23.8 198.2-47.5 298.8-75 157.4-47.6 331.2-90.6 455.1-222.2 125-140.8 84-336.5-102.1-391.8-62.5-20.4-115.2-27.4-180.9-29.3l65.6 166c188.4-191.5 497.6-529.5 321.1-812.5-73.2-107.58-225.4-119.19-337.1-102.58l106.8 109.96c9.1-56.13 3.3-121.57-25.5-178.54-80.5-160.82-281.6-201.18-439.3-156.27l121.1 90.71c-9.8-229.85-256.1-357.58-461.6-309.98-268.8 56.13-446.5 297.41-681.1 398.64l32.8-7.51c-303.3 15.72-587.2 116.89-847.4 246.54-644.9 330.03-1197.5 821.73-1611.9 1413.73-82.2 120.4-158 244.8-211.2 382.2-9.4 24.8 3.1 52.5 27.9 61.9 23.2 8.7 48.8-1.6 59.8-23.1 206.1-358 509.9-660.1 813.5-938.3 308.1-275.9 638.1-540.6 1007.9-724.9 244.4-122.2 506.5-213.17 770.6-226.45 121.2-30.47 224.1-118.94 315.3-182.38 158.8-116.05 378.7-315.62 557.8-162.69 18.3 17.64 34.9 53.95 35.8 75.96.7 53.82 46 96.67 99.8 94.17 65.1-15.2 142.7-22.45 192.4 7.83 44.1 24.95 60.9 58.82 53.6 109.38-7.8 52.41 28.2 101.29 80.6 109.12 43.4 1.99 96.5-9.43 132.1-1.03 35.5 6.03 55.3 18.03 63.4 53.63 22.9 126.26-66.4 240.36-140.1 344.46-50.8 65.8-109.7 131.5-168.2 187.9-38.4 37-39.5 98.1-2.5 136.4 35.1 38.4 82.4 26.3 129.3 35 32.8 5 73.4 15.1 95.6 32.4 7.4 6.4 5.5 6.3 6.2 6.8-2.5 59.7-61.7 94.5-118.6 125.7-169.1 83-372.6 122.3-560.3 168.5-17.6 4.2-32.9 13-44.9 24.8-349.3 339.9-810.6 551-1208.9 839.7-206.2 145.7-405.4 309.6-573.4 504.3-169 193.4-304.7 416.8-392.9 655.3-18.5 52.6 56.1 93.6 87.4 39.6"
      transform="matrix(.02398 0 0 -.02398 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fdd907",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14164.4 13653.4c-992.6-117.3-1913.3 13-2161.5 218.1-217.6 179.9-230.1 372.7-230.1 372.7s1141.8-227.3 2321.8-89.1c1272.6 149 1924.8 580.4 2151.8 683.2 53.6-107 15.4-300-132.5-476.4-247.6-295.3-1106.1-608.7-1949.5-708.5"
      transform="matrix(.02242 0 0 -.02242 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9b00d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16300 14735.8c-6.1-102.3-56-228.2-155.2-346.5-248.3-295.8-1108.3-609.8-1953.4-709.9-994.4-117.4-1916.9 13.2-2165.6 218.5-73.3 60.7-123.2 122.6-157.3 178.1 327.6-198.1 1263.7-315.6 2268.4-196.9 905.8 107.2 1827.7 444 2093.7 761.1 26.5 31.6 49.7 63.5 69.4 95.6"
      transform="matrix(.02237 0 0 -.02237 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11844.5 14009.7c28.5-47.7 71.9-103 138.3-157.9 219.3-181.2 1122.4-305.3 2067.8-193.4 801.1 94.7 1639.3 393.9 1868.3 667 75.1 89.7 112.5 177.8 125.1 247-309.6-161.8-949.4-494.3-2038.5-621.8-915.1-107.1-1795 1.5-2161 59.1zm1467.2-602c-665.2 0-1243.4 104.7-1460.9 284.4-247.7 204.7-265.2 433.5-265.8 443.2-2.1 32 10.9 63.3 35.1 84.6 24.1 21.2 57 29.9 88.5 23.7 11.1-2.3 1132-220.8 2272.8-87.2 1055.9 123.6 1673.8 446.5 1970.7 601.7 55.2 28.9 98.8 51.7 135 68.1 50.8 23 110.4 1.8 135.4-48 77.5-154.7 19.3-390.2-144.8-585.9-266.9-318.3-1128-636.3-2002.8-739.7-257-30.4-516.2-44.9-763.2-44.9"
      transform="matrix(.02258 0 0 -.02258 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15798.3 11079.8c67.4-25.7 154.5-68.3 219.8-98.6 71.5-34.3 142.2-69 213.2-104.7 49-24.6 68.7-84.2 44.2-133.2-27.3-56-102.7-72.1-150.7-32.8-60.6 48.6-122.3 97.1-184 144.6-53.6 40.5-132.7 101.7-187.5 137.1-49 32.5-9.5 108.5 45 87.6"
      transform="matrix(.02332 0 0 -.02332 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15763.7 10574.5c153-114.8 290.6-245.2 388.9-412 42.2-83.2 147.4-271.78-2.2-311.47-58-11.92-114.2 31.96-117.1 90.98-21.2 216.69-182.8 405.09-331.1 556.29-45.3 41.4 11.6 111.8 61.5 76.2"
      transform="matrix(.02348 0 0 -.02348 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15782.6 10471.8c142.6-93.3 273.3-198.7 370.2-342 49.5-77.2 147.2-258.92.5-298.83-60.3-13.73-119.9 32.48-121.9 94.08-20.6 185.25-175.8 341.55-307.3 465.35-47.7 39.8 5.8 114 58.5 81.4"
      transform="matrix(.02297 0 0 -.02297 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15795.8 11170.8c38.4 253.6 204.9 509.5 457 589.6 28.2 9.2 47.2-30 22.8-46.6-143.1-107.8-259-239.7-347-395.8-29.9-52-57.8-107.2-83.2-161.4-12-26.5-53.5-14.5-49.6 14.2"
      transform="matrix(.02216 0 0 -.02216 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14805.3 11882.1c-4.7 0-9.4.4-14.1 1.2-44.9 7.7-75.1 50.4-67.4 95.4 2.6 15.3 277 1534.9 1480.1 1666.8 45.2 5.1 86.1-27.8 91.1-73.2 5-45.3-27.8-86.1-73.1-91.1-1078.9-118.3-1332.9-1516.4-1335.3-1530.6-7-40.1-41.9-68.5-81.3-68.5"
      transform="matrix(.02088 0 0 -.02088 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16293.8 9323.99c6.2-226.48-35.2-454.4-174.7-640.6-76-89.32-255.3-271.1-355.8-115.45-32.7 58.05-12.1 131.61 46 164.25 53.9 23.32 106.4 46.87 152.3 90.77 129.6 126.94 190.8 323.03 211.9 501.03 4.6 75.57 116.2 75.41 120.3 0"
      transform="matrix(.01913 0 0 -.01913 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16004.2 9598.21c127.9-234.91 249.1-472.06 287.7-741.54 8.1-63.95-37.7-122.33-101.6-129.83-63.7-7.42-121.3 38.04-128.8 101.68-.1 60.47-1.7 121.56-12.4 187.28-28.5 180.63-85 361.42-149.3 532.92-24.9 66.19 69.2 110.5 104.4 49.49"
      transform="matrix(.0199 0 0 -.0199 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8810.54 15707.8s-203.11 592.2-858 486.2c-629.03-101.8-812.86-758.3-666.49-1206 153.81-470.4 448.43-686.2 845.13-743.2 414.45-59.5 810.11 100.9 810.11 100.9l-130.75 1362.1"
      transform="matrix(.02521 0 0 -.02521 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f4b68c",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M7778.94 16002c164.28-502.3 478.77-732.9 902.3-793.6 442.42-63.4 864.91 107.9 864.91 107.9l-29.9 311.3c-163.1-50.5-496.87-131.6-841.79-82-430.24 61.8-757.56 283.3-945.74 754.4 2.8-103.6 19.8-205.2 50.22-298"
      transform="matrix(.02361 0 0 -.02361 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8246.71 15555c12.49-37.1 26.63-71.3 42.59-102.9 96.55-192.6 253.69-284.6 456.51-310.3 246.36-31.2 482.69 61.3 482.69 61.3l-70.3 765.2s-117.5 331.7-508.12 268.7c-147.86-24-254.89-96.1-325.25-190-108.22-144.1-129.43-339.9-78.12-492"
      transform="matrix(.02438 0 0 -.02438 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f44a4a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8289.3 15452.1c96.55-192.6 253.69-284.6 456.51-310.3 246.36-31.2 482.69 61.3 482.69 61.3l-70.3 765.2s-117.5 331.7-508.12 268.7c-147.86-24-254.89-96.1-325.25-190 0 0 435.93-155.1 441.8-459.8 5.43-283.6-270.03-221.4-477.33-135.1"
      transform="matrix(.02438 0 0 -.02438 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8802.28 14958.4c-37.97 0-77.01 2.1-116.42 7-286.62 36.3-479.53 195.7-573.48 474.1-57.36 169.8-34.9 371.6 58.55 526.7 62.95 104.4 186.33 236.7 416.65 274 368.39 59.8 559.24-188.7 608.82-328.4 17.32-48.8-8.22-102.5-57.05-119.8-48.64-17.2-102.1 8-119.62 56.5-9.97 26.8-104.61 254.6-402.15 206.4-159.34-25.8-243.44-115.2-285.86-185.6-64.77-107.5-81.09-252.6-41.49-369.8 70.86-210.1 204.09-320.7 419.16-347.8 217.34-27.5 432.35 54.4 434.48 55.2 48.26 18.7 102.61-5.3 121.37-53.5 18.77-48.3-5.01-102.5-53.21-121.4-8.98-3.5-191.6-73.6-409.75-73.6"
      transform="matrix(.02451 0 0 -.02451 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8272.39 14053.1c-66.39 0-134.91 4.3-204.16 14.2-459.98 66-758.73 327.7-913.23 800.2-96.11 293.8-57.6 637.5 100.41 897 140.07 229.8 359.15 374.8 633.65 419.2 717.68 116.3 950.76-536.6 953.01-543.2 16.25-47.4-8.98-98.9-56.34-115.2-47.29-16.2-98.71 8.9-115.08 56.1-7.52 21.4-187.19 514.8-752.61 423.3-220.53-35.7-396.13-151.4-507.76-334.6-129.22-212.1-161.78-505.1-82.89-746.2 132.73-406 376.3-621.1 766.61-677.1 383.22-55 754.49 93 758.19 94.5 46.51 18.8 99.32-3.5 118.06-49.9 18.86-46.5-3.52-99.3-49.96-118.1-14.25-5.8-301.18-120.1-647.9-120.2"
      transform="matrix(.02537 0 0 -.02537 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13907.1 15524.8s-.7 572.5 597.1 675.9c574.3 99.3 928.8-413.6 935.5-844.3 7.2-452.6-183.3-727-509.4-894.4-340.7-174.9-730.6-154.1-730.6-154.1l-292.6 1216.9"
      transform="matrix(.02757 0 0 -.02757 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f4b68c",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16120.4 16033.4c7.4-472.6-191.4-759.2-531.8-933.9-355.7-182.5-762.9-160.7-762.9-160.7l-65.5 272c152.7 4.8 458.6 33.5 735.9 175.9 345.7 177.5 558 460.2 580 913.3 27.8-88.4 43-179.3 44.3-266.6"
      transform="matrix(.0264 0 0 -.0264 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15424.5 15804.4c.2-36.1-1.8-70-6.3-102.3-26.2-196.4-135.3-323.4-304-406.5-204.8-100.9-438-91.2-438-91.2l-168 686.3s2.8 323.7 361.3 385.8c135.7 23.5 250.4-7.1 339.7-67.7 137.3-92.9 214.4-256.8 215.3-404.4"
      transform="matrix(.0265 0 0 -.0265 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f44a4a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M15418.2 15702.1c-26.2-196.4-135.3-323.4-304-406.5-204.8-100.9-438-91.2-438-91.2l-168 686.3s2.8 323.7 361.3 385.8c135.7 23.5 250.4-7.1 339.7-67.7 0 0-332.6-265.5-246.4-532.1 80.2-248.3 301-111.7 455.4 25.4"
      transform="matrix(.0265 0 0 -.0265 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14604.6 15026.2c-11.6 0-18.7.3-20.3.4-47.6 1.9-84.6 42-82.6 89.6 1.9 47.7 41.5 85.2 89.6 82.8 2.1-.2 213.4-6.8 394 82 178.8 88 261.5 223.9 260.2 427.8-.7 113.7-58.3 234.9-146.7 308.9-58.1 48.6-158 101.1-304.1 75.8-273.7-47.3-287.1-274.1-287.7-299.8-1.1-46.9-39.4-84.5-86.2-84.5h-1.5c-47.3.7-85.2 39.3-84.8 86.7 1.3 136.2 92.9 409.2 430.8 467.6 211.5 36.5 358.2-41.5 444.2-113.4 127.6-106.8 207.4-275.5 208.5-440.2 1.7-270-118.3-466.4-356.5-583.7-187.1-92.1-392.3-100-456.9-100"
      transform="matrix(.02667 0 0 -.02667 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14218.7 14200.6c-26.9 0-43.3.8-46.4 1-46 2.4-81.2 41.7-78.8 87.6 2.5 45.9 40.8 81.4 87.6 78.7 3.6-.1 372-16.9 687 144.8 322.3 165.4 469.5 425.1 463.4 817.5-3.7 233-119.6 477.8-295.3 623.4-151.8 125.7-339.1 173.8-541.2 138.8-518.3-89.7-527-572.4-527.1-592.9-.3-45.9-37.5-83.1-83.4-83.1h-.1c-45.9.2-83.1 37.2-83.1 83.1 0 6.4 6.8 643.2 665.2 757 251.7 43.5 485.6-16.8 676-174.6 214.8-178.1 351.1-465.1 355.6-749.1 7.1-456.6-174.1-773.4-554-968.4-289.6-148.7-607.9-163.8-725.4-163.8"
      transform="matrix(.02761 0 0 -.02761 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#b17e4f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m8621.41 16257.3 114.11 42.7c-38.32-13.6-76.39-27.6-114.11-42.7"
      transform="matrix(.03079 0 0 -.03079 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#d2623a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M6812.29 10633.8c145.71-303.3 636.06-605.3 1360.67-635.73l3211.44 620.53c661.1 298.3 1003.6 761.3 1025.9 1096.9 16.5 250-91.3 566.9-454.4 653 140.5 336.8 154.4 793.6 85.2 1205.5-125.8 747.8-598.4 1343.3-1232.3 1699.6-147.6 83-336 162.8-555.8 227.4-43.9 339.6-145.5 502.5-204.5 591.9-19.9 30-62.74 33.6-87.41 7.3-71.32-76.1-139.93-197.6-190.88-238.5-27.89-22.3-69.24-13.4-85.15 18.7-71.36 143.8-181.03 322.4-242.83 391.2-25.57 28.4-71.6 23.3-90.35-9.9-68.19-120.9-243.64-362.7-517.53-527.1-240.46-144.4-377.98-199.3-548.88-272.3h-.14c-612.29-252.3-1066.89-624.3-1312.51-930-455.45-566.8-672.28-1295.5-510.38-2036.4 89.21-408 272.43-826.8 528.23-1086.9-304.91-215.1-286.82-549.4-178.38-775.2"
      transform="matrix(.03248 0 0 -.03248 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#e2997f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M6542.83 13392.6c62.77 417.8 241.2 824.2 524.84 1177.2 286.92 357 768.98 698.3 1289.82 913.2.9.4 1.81.7 2.76 1.1l40.05 17.1c157.58 67 293.68 124.9 520.55 261.2 307.01 184.3 483.36 450.9 535.12 537.6 58.24-72.1 154.53-229.8 219.96-361.5 17.19-34.7 48.72-59.5 86.53-67.8 9.24-2.1 18.52-3.1 27.76-3.1 28.67 0 56.72 9.7 79.77 28.1 34.86 28 68.76 75.1 108 129.6 25.81 35.8 52.31 72.7 78.51 102.9 51.9-79.7 143.2-235.1 183.7-548.8 3.7-28 23.5-51.2 50.6-59.2 203.5-59.8 391.6-136.7 544.1-222.4 394.8-222 711.8-532.6 925.8-897-219.5 314.9-513.5 574.5-853.5 765.7-148.4 83.5-337.9 163.7-559 228.7-69.9 22.2-168 40.3-170.8 54.8-3.3 17.3-1.2 38.4 4.5 60.9 0 .5.2 1.2.3 1.7 32.4 168.6-91.5 483.5-127.5 485.1h-.3c-28.7 0-181.12-183.3-232.41-223.9-27.96-22.2-49.48-31.5-67.15-31.5-14.95 0-27.19 6.7-38.34 17.6-53.52 52.8-174.15 337.4-224.72 400.4-12.24 15.4-20.05 23.8-27.29 23.8-6.85 0-13.23-7.6-22.43-24-68.62-121.5-245.01-364.6-520.5-530-241.77-145.3-380.11-200.4-551.93-273.8l-.19-.1c-32.29-13.3-64.24-26.9-95.76-41 1.66-2.4 3.28-4.9 4.8-7.6 114.11-191.8-23.47-484.4-307.49-653.4-130.19-77.5-268.48-115.5-391.16-115.5-128.15 0-239.15 41.5-305.77 123-87.44-82.4-162.53-163.7-224.54-240.7-244.29-304.1-420.35-654.5-506.69-1028.4"
      transform="matrix(.0323 0 0 -.0323 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#bc462f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M8941.2 13956.9c191.25-397.9 834.84-794.3 1785.9-834.3l4215.1 814.5c867.7 391.5 1317.2 999.2 1346.4 1439.7 11.4 171.2-21.8 366.3-123.7 529.7-68.9-433.1-515.7-999-1338.5-1370.2l-4215.1-814.6c-901.89 37.9-1527.33 396.6-1752.64 772.8-33.75-189.5 8.21-383 82.54-537.6"
      transform="matrix(.02475 0 0 -.02475 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M6848.95 10622.2c126.83-263.8 583.22-561.1 1286.47-592.8l3182.48 615c640.8 291.5 953.6 737.4 973 1029.5 15.1 229.7-81.6 502-398.5 577.1-20.2 4.7-37.2 18.1-46.6 36.6-9.4 18.4-10.2 40.1-2.3 59.2 123.1 295 153.2 729.3 80.5 1161.8-114.8 682.8-549.5 1281.7-1192.5 1643.2-151 84.9-337.4 161.1-538.9 220.3-26.8 7.9-46.5 30.9-50.1 58.6-40.2 310.7-130.6 464.6-181.96 543.6-25.94-29.9-52.26-66.4-77.78-101.9-38.86-54-72.44-100.6-106.97-128.4-30.19-24.1-68.96-33.1-106.5-24.8-37.45 8.3-68.68 32.8-85.7 67.2-64.81 130.4-160.18 286.7-217.87 358-51.27-85.8-225.93-349.9-530.01-532.4-224.7-135.1-359.51-192.4-515.58-258.7l-39.67-17c-.94-.3-1.84-.7-2.73-1-515.87-212.9-993.34-551-1277.52-904.6-462-575-642.22-1292.7-494.4-1969.1 93.67-428.5 283.38-820.3 507.51-1048.3a70.189 70.189 0 0 0 19.96-55.7c-1.84-20.6-12.64-39.4-29.58-51.3-266.11-187.8-254.42-476.5-154.75-684.1M8140.66 9888c-.99 0-1.98.05-2.97.05-770.14 32.4-1266.05 361.45-1415.9 673.15-110.99 231.1-134.76 572.3 137.58 810.8-220.88 252.4-398.18 633.2-491.38 1059.5-156.97 718.2 33.39 1479.2 522.28 2087.6 298.71 371.7 797.92 725.8 1335.43 947.3l1.18.5 38.06 16.2c151.22 64.3 281.83 119.8 498.13 249.8 300.46 180.3 461.25 447.5 490.44 499.2 19.91 35.2 55.85 58.9 96.18 63.4 40.51 4.5 80.6-11 107.68-41.1 68.72-76.5 175.13-252.8 243.57-387.4 19.15 20.9 44.48 56.1 67.35 87.8 30.8 42.8 65.7 91.3 101.98 130 26.6 28.3 63.76 42.6 102.68 39.4 38.65-3.2 73.15-23.6 94.55-55.9 52.2-78.9 158.1-239.3 208.5-574.1 194.9-60.1 375.5-135.6 524.6-219.4 680.4-382.5 1140.6-1017.8 1262.5-1742.8 72.4-430.3 49.7-849.7-61.3-1166.2 341.7-119.9 446.7-445.4 429.8-701.2-22.9-345.1-360.5-835.2-1063.2-1152.2-5-2.3-10.2-3.9-15.6-5l-3198.79-618.13c-4.39-.85-8.87-1.27-13.35-1.27"
      transform="matrix(.03261 0 0 -.03261 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f9d6b6",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M7613.76 11618.3c45.88-256.6 343.9-580.1 690.32-673.9 193.55-50.6 1035.35-272.4 2356.32-17.2 1320.9 255.3 2019.5 774.8 2180.3 893.8 286.5 216.1 442.5 627.4 389.4 882.7-89.3 429.8-565.4 449.5-565.4 449.5s338.4 1232.1-463 2051c-6.3 6.6-13 13.4-19.6 19.8-1085.9 1076-2375.04 118.9-2238.68-586.6-136.36 705.5-1689.29 1113.4-2296.11-289.8-3.75-8.3-7.4-17.1-10.74-25.6-438.65-1058.6 334.4-2075.8 334.4-2075.8s-434.44-195.7-357.21-627.9"
      transform="matrix(.02994 0 0 -.02994 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f4b68c",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9342.94 14257c56.3-314.9 422-711.9 847.06-827 237.6-62 1270.5-334.2 2891.5-21 1620.9 313.3 2478.1 950.7 2675.4 1096.7 351.6 265.3 543.1 769.9 478 1083.1-11.6 55.6-28.3 105.6-49.4 150.3-13.4-304.9-593-1516.5-3165.2-2013.5-2572.2-497.1-3561.54 411.3-3687.45 689.3-2.96-49.4.19-102 10.09-157.9"
      transform="matrix(.0244 0 0 -.0244 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M9134.35 15561.6c-530.84 0-986.38-332.1-1236.76-911-3.73-8.4-6.99-16.2-9.99-23.8-426.36-1028.9 324.04-2037.9 331.67-2047.9 14.46-19.1 19.4-43.8 13.51-66.9-5.94-23.3-22.03-42.5-43.85-52.4-15.72-7.1-387.51-181.7-320.42-556.9 40.12-224.5 316.69-537.4 649.51-627.6 864.5-225.8 1768.98-133.4 2375.68-16.2 606.7 117.3 1480.5 368.5 2197.7 899.5 276.2 208.3 416.3 601.8 369.9 825.1-77.5 373.1-487.7 396.6-505.1 397.5-23.8 1.1-46 13-60.1 32.3-14 19.4-18.6 44-12.3 67 3.4 12.1 323.8 1228-454.2 2023-6.6 7-12.6 13-18.5 18.8-488.1 483.7-1092.3 605-1616.8 324.9-371.4-198.3-599.4-557.4-542.1-853.8 1-5.1 1.4-10.1 1.4-15.1 0-36.9-26.1-69.8-63.7-77-42.6-8.4-83.9 19.6-92.1 62.2-57.3 296.5-402.7 544.8-821.28 590.4-47.9 5.2-95.32 7.9-142.17 7.9m536.14-4582c-383.87 0-791.2 38.6-1191.01 143.1l-2.58.6c-395.34 107.1-713.81 475.2-763.23 751.8-60.73 339.7 155.9 573.5 328.2 683-174.62 262.6-696.67 1170.5-300.07 2127.6 3.26 8.4 7.46 18.4 11.78 28.1 301.01 695.9 877.27 1068.5 1539.98 996.3 350.49-38.3 651.03-198.2 821.14-419.7 75.3 268.9 294.5 529.3 605.6 695.3 588.5 314.3 1261.7 182.9 1800.9-351.3 6.9-6.8 14.6-14.5 21.8-22.1 723.8-739.6 577.4-1776.5 513.2-2085.2 200.7-37.4 488.8-173.7 559.1-511.6 57.1-275.1-101.3-735.3-428.3-982-742.9-550-1640.7-808.6-2263.4-929-343.6-66.4-780.6-124.9-1253.11-124.9"
      transform="matrix(.02926 0 0 -.02926 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11803.2 16078.3c23-141.1-164.1-288.8-417.8-330.1-253.7-41.4-477.9 39.4-500.9 180.4-22.9 141 164.1 288.8 417.8 330.1 253.7 41.3 477.9-39.5 500.9-180.4"
      transform="matrix(.02205 0 0 -.02205 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M16278.6 15878.9c21.4-131.6-153.2-269.6-390-308.2-236.9-38.6-446.3 36.8-467.7 168.5-21.5 131.6 153.2 269.6 390 308.1 236.9 38.6 446.2-36.8 467.7-168.4"
      transform="matrix(.02361 0 0 -.02361 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13388 16111.3c19.7-2.4 35.2 16.4 29.3 35.4-17.8 57.1-60.8 153.3-147.9 152.8-115.4-.6-111.7-100.3-96.8-127.7 17.3-32.1 49.2-48.7 107.3-51.3 35.6-1.6 77.5-5.6 108.1-9.2"
      transform="matrix(.02294 0 0 -.02294 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13589.1 16049.7c-17.9-8.4-38.3 4.4-38.7 24.2-1.3 59.3 8.8 163.3 91 190.3 108.7 35.8 136.7-59.2 131.4-89.7-6.2-35.6-31.1-61.4-84.9-82.1-33-12.7-71.2-29.6-98.8-42.7"
      transform="matrix(.02312 0 0 -.02312 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M10627.8 15424c69.1-379.8 389.6-633.3 716-566.3 326.3 67 534.9 429.2 465.8 809-69 379.8-389.6 633.3-715.9 566.3-326.4-67-535-429.2-465.9-809"
      transform="matrix(.02457 0 0 -.02457 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11608.9 15930.9c30.1-165.5 152-279.7 272.3-255 120.2 24.7 193.3 178.9 163.2 344.4-30.1 165.5-152 279.7-272.3 255-120.2-24.7-193.3-178.9-163.2-344.4"
      transform="matrix(.02376 0 0 -.02376 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#9b5959",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M11200.1 15851c-79.7 0-144.4 100.6-144.4 224.5 0 124 64.7 224.5 144.4 224.5s144.4-100.5 144.4-224.5c0-123.9-64.7-224.5-144.4-224.5"
      transform="matrix(.02398 0 0 -.02398 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14135.3 15535.7c-24.7-377.6-308.5-660.3-633.8-631.6-325.2 28.7-568.9 358.1-544.1 735.6 24.8 377.5 308.5 660.3 633.8 631.6 325.3-28.8 568.9-358.1 544.1-735.6"
      transform="matrix(.02507 0 0 -.02507 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14020 15972.5c-10.8-164.6-116.7-289.3-236.6-278.8-119.7 10.6-208.2 152.6-197.4 317.1 10.8 164.5 116.7 289.2 236.5 278.6 119.9-10.6 208.2-152.5 197.5-316.9"
      transform="matrix(.02425 0 0 -.02425 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#9b5959",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14114.6 15863.4c-73.4 0-141.3 87.5-154.5 202.2-13.8 120.2 37.7 224.9 115 233.8 3.5.4 7 .6 10.5.6 73.4 0 141.3-87.4 154.5-202.2 13.8-120.2-37.7-224.9-115-233.8-3.5-.4-7-.6-10.5-.6"
      transform="matrix(.02456 0 0 -.02456 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M14480.4 16217.9c-153.7-225.2-465.9-347.3-728-242.1-84.6 30.6-322.1 195.7-202.7 287.2 33.5 23.1 78.7 16.3 104.2-14.3 92.2-107.6 222-162.3 364.2-154.5 147.4 7.6 292.2 73.1 403.1 174.1 33.1 31.7 85.2-12.9 59.2-50.4"
      transform="matrix(.02218 0 0 -.02218 -53.725 554.908)"
    />
    <path
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M13658.3 16193.9c-70.8-59-125.3-119.9-153.7-207.7-5.9-15.6-11.6-35.4-21.6-52.3-10.6-20.3-35.7-28-55.9-17.4-23.9 15.1-30.5 45.3-39.4 70.7-27.9 104.5 38 224.1 136.4 265 41.2 16.8 156.3 47.8 154.3-26.9-1.3-13.5-10.9-22.9-20.1-31.4"
      transform="matrix(.0223 0 0 -.0223 -53.725 554.908)"
    />
  </svg>
)

export default SvgComponent
