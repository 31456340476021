import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MonkeyLaunch from "../components/monkey_launch"

const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <div className="flex flex-col justify-center items-center">
      <MonkeyLaunch />
      <p>
        Thanks! We'll let you know when we launch data chimp for Julia, Python,
        or SQL
      </p>
      <Link className="text-black" to="/" state={{ modal: true }}>
        Go back to the homepage
      </Link>
    </div>
  </Layout>
)

export default SecondPage
